<ngx-spinner type="ball-atom" bdColor="rgba(0, 0, 0, 1)">
  <p class="loadText">Cargando...</p>
</ngx-spinner>
<div class="mt-5 container-fluid">
  <div class="d-flex align-items-center tituloModulo mb-2">
    <i class="fa-solid fa-users-gear iconTitulo"></i>
    <h3>Usuarios</h3>
  </div>
  <div class="col-md-12">
    <div class="d-flex row justify-content-between">
      <div class="col-md-12 d-flex row">
        <div class="col-md-3 mb-3">
          <!-- <label for="exampleInputEmail1" class="form-label"><strong>Buscar</strong></label> -->
          <div class="d-flex flex-row pt-3">
            <div class="col-md-12 d-flex pe-3">
              <input type="text" name="documento" class="form-control EmiInput" value="" autocomplete="off"
                (keyup.enter)="applyFilter($event)" #ValorBuscar (input)="applyFilter($event)" placeholder="Nombre o rol"/>
              <div class="btn-buscar d-flex justify-content-center ms-3" (click)="BuscarUsuario(ValorBuscar.value)">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 d-flex ">
          <div class="d-flex justify-content-end form-switch checkboxesp col-md-12  d-flex ">
            <div class="mb-3">
              <!-- <label for="exampleFormControlTextarea1" class="form-label">Estado:</label> -->
              <div class="form-check form-switch pt-4">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="Yes" #checkUnput
                  (click)="FiltrarUser()">
                <label class="form-check-label" for="flexSwitchCheckDefault">{{estado}} </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="d-flex flex-column">
      <div class="col-md-12">
        <div class="table-responsive scrollBlack">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="table table-striped table-fixed mat-elevation-z3">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por nombre"> Nombre </th>
              <td mat-cell *matCellDef="let user"> {{user.name}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por correo"> Correo </th>
              <td mat-cell *matCellDef="let user"> {{user.email}} </td>
            </ng-container>
            <ng-container matColumnDef="username">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por usuario"> Usuario </th>
              <td mat-cell *matCellDef="let user"> {{user.username}} </td>
            </ng-container>
            <ng-container matColumnDef="descripcion_rol">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por rol"> Rol </th>
              <td mat-cell *matCellDef="let user"> {{user.descripcion_rol}} </td>
            </ng-container>

            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef> Acciones </th>
              <td mat-cell *matCellDef="let user">
                <div>
                  <a (click)="VerDetalle('1', user); $event.stopPropagation()" class="icon-table"
                    data-toggle="tooltip" data-placement="top" title="Ver Detalle">
                    <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                  </a>
                  <a class="icon-table" data-toggle="tooltip" data-placement="top" title="Actualizar Usuario"
                    (click)="ActuTecnico('1', user.id); $event.stopPropagation()">
                    <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
                  </a>
                  <a class="icon-table" data-toggle="tooltip" data-placement="top" title="Cambiar Contraseña"
                    (click)="openRestablecer(user.id); $event.stopPropagation()">
                    <fa-icon [icon]="['fas', 'key']"></fa-icon>
                  </a>
                  <ng-container *ngIf="user.descripcion_rol == 'Usuario'">
                    <a class="icon-table" data-toggle="tooltip" data-placement="top" title="Asociar Técnico"
                      (click)="openAsociar(user.id,user.nombre_tecnico); $event.stopPropagation()">
                      <fa-icon [icon]="['fas', 'users-gear']"></fa-icon>
                    </a>
                  </ng-container>
                </div>
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="['name', 'email', 'username', 'descripcion_rol', 'acciones']"></tr>
            <tr mat-row (click)="VerDetalle('1', dataSource)"
              *matRowDef="let dataSource; columns: ['name', 'email', 'username', 'descripcion_rol', 'acciones']">
            </tr>

          </table>
          <mat-paginator [pageSizeOptions]="[items]" showFirstLastButtons aria-label="Seleccionar paginas">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Ver Detalle -->
<div class="modal fade" id="VerDetalle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Detalles del Usuario</h3>
        <!-- X -->
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="VerDetalle('0', {})"></button>
        <!--  -->
      </div>

      <!-- Body de Modal -->
      <div class="modal-body">
        <div class="col-md-12 mb-3">
          <div class="d-flex row">
            <div class="col-md-3">
              <h6 class="font-detalle">
                <strong class="strong">ID: </strong>
                {{ DetalleUsuario.id }}
              </h6>
            </div>
            <div class="col-md-12">
              <h6 class="font-detalle">
                <strong class="strong">Nombre de Usuario: </strong>
                {{ DetalleUsuario.username }}
              </h6>
            </div>
            <div class="col-md-">
              <h6 class="font-detalle">
                <strong class="strong">Nombre: </strong>
                {{ DetalleUsuario.name }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Estado: </strong>
                {{ DetalleUsuario.active == "A" ? "Activo" : "Inactivo" }}
              </h6>
            </div>
            <div class="col-md-12">
              <h6 class="font-detalle">
                <strong class="strong">Descripcion del Rol: </strong>
                {{ DetalleUsuario.descripcion_rol }}
              </h6>
            </div>
            <!-- <ng-container *ngIf="DetalleUsuario.nombre_tecnico != null || DetalleUsuario.nombre_tecnico != ''"> -->
              <div class="col-md-12">
                <h6 class="font-detalle">
                  <strong class="strong">Tecnico Asociado: </strong>
                  {{ DetalleUsuario.nombre_tecnico }}
                </h6>
              </div>
            <!-- </ng-container> -->
          </div>
          <div class="d-flex row  pe-3 justify-content-end">
            <button type="button" class="btn btn-Cancelar" (click)="VerDetalle('0', {})">
              Aceptar
            </button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
<!--  -->

<!-- Modal para Editar Usuario -->
<div class="modal fade" id="ActuTecnico" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <!-- <div class="modal-dialog"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Editar Usuario</h3>
        <!-- X -->
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="ActuTecnico('0')"></button>
        <!--  -->
      </div>

      <!-- Body de Modal -->
      <div class="modal-body">

        <form [formGroup]="UserForm" (ngSubmit)="ProcesarEditarUser(status.value,rol_status)" id="fromUser">
          <div class="form-row">

            <div class="input-group mb-0">

              <!-- Nombre -->
              <div class="col-md-6 mb-3">
                <label for="Nombre" class="form-label"><strong>Nombre:</strong></label>
                <input type="text" name="Nombre" class="form-control EmiInput"
                  [value]="DetalleUsuario.NombreCompleto[0]" formControlName="name" />

                <!-- Validar que el campo no este Vacio -->
                <div class="container-error-message" *ngIf="UserForm.get('name')?.touched
                                && UserForm.get('name')?.errors?.['required']
                                " id="error_input">
                  Es requerido este campo
                </div>
              </div>

              <!-- Apellido -->
              <div class="col-md-6 mb-3 px-2">
                <label for="apellido" class="form-label"><strong>Apellido:</strong></label>
                <input type="text" name="apellido" class="form-control EmiInput"
                  [value]="DetalleUsuario.NombreCompleto[1]" formControlName="apellido" />

                <!-- Validar que el campo no este Vacio -->
                <div class="container-error-message" *ngIf="UserForm.get('apellido')?.touched
                                && UserForm.get('apellido')?.errors?.['required']
                                " id="error_input">
                  Es requerido este campo
                </div>
              </div>

            </div>

            <!-- input Group 1 -->
            <div class="input-group mb-0">

              <!-- Correo -->
              <div class="col-md-6 mb-3 px-2">
                <label for="email" class="form-label"><strong>Email:</strong></label>
                <input type="text" name="email" class="form-control EmiInput" [value]="DetalleUsuario.email"
                  formControlName="email" />
                <!-- Validar que el campo no este Vacio -->
                <div class="container-error-message" *ngIf="UserForm.get('email')?.touched
                                  && UserForm.get('email')?.errors?.['required']
                                  " id="error_input">
                  Es requerido este campo
                </div>

                <!-- Validar que el campo no este Vacio -->
                <div class="container-error-message" *ngIf="UserForm.get('email')?.touched
                                && UserForm.get('email')?.errors?.['email']
                                " id="error_input">
                  Debe Ingresar un Email Correcto
                </div>

                <div
                class="container-error-message"
                *ngIf="UserForm.get('correo')?.touched
                  && UserForm.get('correo')?.errors?.['emailDomain']
                  "
                id="error_input"
              >
                El correo debe ser de airtek.com.ve
              </div>

              </div>

              <!-- Estado -->
              <div class="col-md-6 mb-3">
                <label for="estado" class="form-label"><strong>Estado:</strong></label>

                <select class="form-select EmiInput" aria-label="Default select example" #status
                  [value]="DetalleUsuario.active">
                  <option *ngFor="let est of estatusd" [value]="est.value">
                    {{ est.name }}
                  </option>
                </select>

              </div>

            </div>

            <!-- input Group 2 -->
            <div class="input-group mb-2">
              <!-- Rol solo se Muestran los roles-->
              <ng-container *ngIf="
            DetalleUsuario.nombre_rol !== 'ROLE_USER' &&
                  DetalleUsuario.nombre_rol !== 'ROLE_SERVICES' &&
                  DetalleUsuario.nombre_rol !== 'ROLE_SUPERADMIN' ">

                <div class="col-md-6 mb-3">
                  <label for="exampleInputEmail1" class="form-label"><strong>Rol del Usuario:</strong></label>

                  <select class="form-select EmiInput" aria-label="Default select example" #rol_status>
                    <ng-container *ngFor="let listRoles of listRoles">

                      <option selected *ngIf="listRoles.name == DetalleUsuario.nombre_rol" [value]="listRoles.name">
                        {{ listRoles.description }}
                      </option>

                      <option *ngIf="listRoles.name != DetalleUsuario.nombre_rol" [value]="listRoles.name">
                        {{ listRoles.description }}
                      </option>
                    </ng-container>
                  </select>


                </div>
              </ng-container>

            </div>

          </div>

          <div class="d-flex row justify-content-end ">
            <button type="button" class="btn btn-Cancelar me-2" (click)="ActuTecnico('0')">
              Cancelar
            </button>

            <button type="submit" class="btn btn-Procesar me-2">
              Procesar
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>


<div class="modal fade" id="Pass" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Restablecer Contraseña</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form [formGroup]="passForm" (ngSubmit)="ProcesarPassword()" novalidate>
        <div class="modal-body">
          <div class="d-flex row justify-content-center gap-3">
            <div class="col-md-5 mb-3 formEmi">
              <label for="exampleInputEmail1" class="form-label"><strong>Contraseña</strong></label>
              <div class="case">
                <input [type]="tipo" name="password" class="form-control EmiInput" formControlName="password"
                  autocomplete="off" id="password" #pass />


                <div class="caseEye">
                  <fa-icon [icon]="['fas', 'eye']" class="iconEye" (click)="mostrarContrasena()" id="eye"></fa-icon>
                  <fa-icon [icon]="['fas', 'eye-slash']" class="iconEye d-none" (click)="mostrarContrasena()"
                    id="eyeSlash"></fa-icon>
                </div>

              </div>
              <div class="container-error-message" *ngIf="passForm.get('password')?.touched
            && passForm.get('password')?.errors?.['required']
            " id="error_input">
                el campo es requerido
              </div>
              <div class="container-error-message" *ngIf="passForm.get('password')?.touched
      && passForm.get('password')?.errors?.['minlength']
      " id="error_input">
                Ingrese una contraseña mayor a seis (06) digitos
              </div>
            </div>
            <div class="col-md-5 mb-3 formEmi">
              <label for="exampleInputEmail1" class="form-label"><strong>Repetir Contraseña</strong></label>
              <div class="case">
                <input [type]="tipo1" name="Rpassword" class="form-control EmiInput" formControlName="Rpassword"
                  autocomplete="off" id="rPasword" #rPass />
                <div class="caseEye">
                  <fa-icon [icon]="['fas', 'eye']" class="iconEye" (click)="mostrarContrasena1()" id="eye1"></fa-icon>
                  <fa-icon [icon]="['fas', 'eye-slash']" class="iconEye d-none" (click)="mostrarContrasena1()"
                    id="eyeSlash1"></fa-icon>
                </div>

              </div>
              <div class="container-error-message" *ngIf="passForm.get('Rpassword')?.touched
            && passForm.get('Rpassword')?.errors?.['required']
            " id="error_input">
                El campo es requerido
              </div>
              <div class="container-error-message" *ngIf="passForm.get('Rpassword')?.touched
      && passForm.get('Rpassword')?.errors?.['minlength']
      " id="error_input">
                Ingrese una contraseña mayor a seis (06) digitos
              </div>
              <div class="container-error-message" id="error_input" *ngIf="passForm.get('Rpassword')?.errors?.['confirmedValidator'] ||
                  passForm.get('password')?.errors?.['confirmedValidator']">
                Las contraseñas no coinciden
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-Cancelar" (click)="saveRestablecer()">
            Cancelar
          </button>
          <button type="submit" class="btn btn-Procesar me-2">Procesar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="asociar" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Asociar Técnico</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form [formGroup]="asociarForm" (ngSubmit)="procesarAsociar()" novalidate>
        <div class="modal-body">
          <div class="d-flex row justify-content-center">
            <!-- <div class="col-md-5">
            <h6>Tecnico Asignado:</h6>
          </div>
          <div class="col-md-5">
            <h6>{{TecnicoAsignado}}</h6>
          </div>
          <hr class="my-3" id="hr"/> -->
            <div class="col-md-5 mb-3 formEmi" id="div1">
              <label for="exampleInputEmail1" class="form-label"><strong>Buscar Técnico</strong></label>
              <div class="d-flex flex-row">
                <input type="text" name="buscar" class="form-control EmiInput" placeholder="Nombre Técnico" #buscar  (keyup)="onKeyUpEvent($event)" />
                <!-- <button type="button" class="btn-buscar d-flex justify-content-center ms-3" id="buscarCliente"
                  (click)="BuscarTecnico(buscar.value)">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button> -->
              </div>
            </div>
            <div class="col-md-5 mb-3 formEmi" id="div2">
              <label for="exampleInputEmail1" class="form-label"><strong>Tecnico a Asociar</strong></label>
              <select name="rol" class="form-control EmiInput" autocomplete="off" formControlName="tecnico" #tecnico>
                <option value="">Seleccione</option>
                <ng-container *ngFor="let listTecnicos of listTecnicos">
                  <option [value]="listTecnicos.id_tecnico">
                    {{ listTecnicos.nombre_tecnico }}
                  </option>
                </ng-container>
              </select>
              <div class="container-error-message" *ngIf="asociarForm.get('tecnico')?.touched
            && asociarForm.get('tecnico')?.errors?.['required']
            " id="error_input">
                El campo es requerido
              </div>
            </div>


          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-Procesar me-2">Aceptar</button>
        </div>
      </form>
    </div>
  </div>
</div>
