import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Modulos importados a Route
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CanActivateAuthGuard } from './services/guard/auth-guard.service';

//Notificaciones
import { HomeLayoutComponent } from './home-layout/home-layout.component';

import { GestionTecnicosComponent } from './modules/mant-planta-externa/cuadrillas/gestion-tecnicos/gestion-tecnicos.component';
import { GestionCuadrillaComponent } from './modules/mant-planta-externa/cuadrillas/gestion-cuadrilla/gestion-cuadrilla.component';
import { RegistrarUsuariosComponent } from './modules/administrador/usuarios/registrar-usuarios/registrar-usuarios.component';
import { GestionarUsuariosComponent } from './modules/administrador/usuarios/gestionar-usuarios/gestionar-usuarios.component';
import { EmisionPageComponent } from './modules/operaciones/averias-menores/emisionv2/emisionv2.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    // children: [
    //   {
    //     path: 'login', // child route path
    //     component: LoginComponent, // child route component that the router renders
    //   }]
  },
  {
    path: '',
    canActivate: [CanActivateAuthGuard],
    component: HomeLayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },

      // Rutas para averias-menores
      {
        path: 'AveriasMenores',
        loadChildren: () => import('./modules/operaciones/averias-menores/averias-menores.module').then( m => m.AveriasMenoresModule)
        // children: [
        //   {
        //     path: '',
        //     component: HomeGoogleComponent,
        //   },
        //   {
        //     path: 'Emision',
            // component: EmisionComponent,
        //     component: EmisionPageComponent,
        //   },
        //   {
        //     path: 'GestionAverias',
        //     component: GestionaveriasComponent,
        //   },
        //   {
        //     path: 'HistorialProham',
        //     component: HistorialProhamComponent,
        //   },
        //   {
        //     path: 'Estadisticas',
        //     component: EstadisticasComponent,
        //   },
        // ],
      },
      //Ruta Mant. Planta Externa
      {
        path: 'Cuadrillas',
        children: [
          {
            path: 'GestionCuadrillas',
            component: GestionCuadrillaComponent
          },
          {
            path: 'GestionTecnicos',
            component: GestionTecnicosComponent
          }
        ]
      },
      //rutas usuarios
      {
        path: 'Usuarios',
        children: [
          {
            path: 'RegistrarUsuarios',
            component: RegistrarUsuariosComponent,
          },
          {
            path: 'GestionarUsuarios',
            component: GestionarUsuariosComponent,
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes,{ useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
