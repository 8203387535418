<ngx-spinner type="ball-atom" bdColor="rgba(0, 0, 0, 0.9)"
  ><p class="loadText">Cargando...</p></ngx-spinner
>
<div class="mt-5 container-fluid contRes">
  <div class="d-flex align-items-center tituloModulo mb-2">
    <i class="fa-solid fa-people-group iconTitulo"></i>
    <h3>Gestión de Cuadrillas</h3>
  </div>
  <div class="d-flex justify-content-end boton-flotante">
    <ng-container *ngIf="linkTecnicos != ''">
      <input
        type="button"
        class="btn btn-Procesar me-4 btnRes"
        value="Gestión Técnicos"
        routerLink="{{ linkTecnicos === null ? '/home' : linkTecnicos }}"
      />
    </ng-container>
  </div>
  <div class="col-md-12 mt-5 tecRes" cdkDropListGroup>
    <div class="d-flex row div-container">
      <div class="col-md-5">
        <div
          cdkDropList
          [cdkDropListData]="listaDeTecnicos"
          class="example-list"
          (cdkDropListDropped)="Drop1($event)"
        >
          <div class="d-flex justify-content-center m-2 titulo-card">
            <h5>Técnicos</h5>
          </div>
          <!-- <div class="d-flex justify-content-end m-3 icon-card">
            <a class="icon-Cuadrilla"
              ><i class="fa-sharp fa-solid fa-plus"></i>
            </a>
          </div> -->
          <div class="d-flex row justify-content-start mb-2">
            <div class="col-md-8 mb-2">
              <label for="exampleInputEmail1" class="form-label"
                ><strong>Buscar Técnico</strong></label
              >
              <!-- (keyup.enter)="KeypressEnter(nombreTecnicoBuscar.value)" -->
              <input
                type="Text"
                name="documento"
                class="form-control EmiInput"
                #nombreTecnicoBuscar
                (keyup)="onKeyUpEvent($event)"

              />
            </div>
          </div>
          <div class="LimitDiv scrollBlack">
            <div class="list-tecnicos">
              <div
                class="example-box"
                *ngFor="let tecnicos of listaDeTecnicos"
                cdkDrag
              >
                <!-- [ngStyle]="{'background-color': (vars.state=='Signup') ? '#73c7af' : '#ffffff'} -->
                <ng-container *ngIf="tecnicos.tipo_tecnico == '1'">
                  {{ tecnicos.nombre_tecnico }} | Técnico Líder
                  <fa-icon [icon]="['fas', 'user-tie']"></fa-icon>
                </ng-container>
                <ng-container *ngIf="tecnicos.tipo_tecnico == '2'">
                  {{ tecnicos.nombre_tecnico }} | Técnico
                  <fa-icon [icon]="['fas', 'hammer']"></fa-icon>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div class="example-list">
          <div class="d-flex justify-content-center m-2 titulo-card">
            <h5>Cuadrillas</h5>
          </div>
          <div class="d-flex justify-content-end m-3 icon-card">
            <a
              class="icon-Cuadrilla"
              (click)="openAddCuadrilla()"
              data-toggle="tooltip"
              data-placement="top"
              title="Añadir Cuadrilla"
              ><i class="fa-sharp fa-solid fa-plus"></i>
            </a>
          </div>
          <div class="d-flex row justify-content-start mb-2">
            <div class="col-md-8 mb-2">
              <label for="exampleInputEmail1" class="form-label"
                ><strong>Buscar Cuadrilla</strong></label
              >
              <input
                type="Text"
                name="documento"
                class="form-control EmiInput"
                #nombreTecnicoBuscar
                (keyup)="onKeyUpEvent2($event)"

              />
            </div>
          </div>
          <div class="LimitDivCuadrilla scrollBlack">
            <ng-container
              *ngFor="let Cuadrillas of listaDeCuadrillas; let i = index"
            >
              <div
                cdkDropList
                [cdkDropListData]="listaDeCuadrillas[i].tecnicosResponse"
                class="list-Cuadrilla mb-3"
                (cdkDropListDropped)="Drop($event, listaDeCuadrillas[i])"
              >
                <div class="d-flex justify-content-between p-2 Titulo-Dram">
                  <h6>{{ Cuadrillas.descripcion }}</h6>
                  <Div class="div-icon-cuadrillas">
                    <!-- Abrir modal de Editar -->
                    <a
                      class="icon-edit"
                      (click)="openedit(Cuadrillas)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Actualizar Cuadrilla"
                    >
                      <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
                    </a>
                    <a
                      class="icon-edit"
                      (click)="openeliminar(Cuadrillas.id_cuadrilla, i)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Eliminar Cuadrilla"
                    >
                      <fa-icon [icon]="['fas', 'square-minus']"></fa-icon>
                    </a>
                  </Div>
                </div>
                <div
                  class="example-box"
                  *ngFor="
                    let nombreTecnico of Cuadrillas.tecnicosResponse;
                    let x = index
                  "
                  cdkDrag
                >
                  <ng-container *ngIf="nombreTecnico.tipo_tecnico == '1'">
                    {{ nombreTecnico.nombre_tecnico }} | Técnico Líder
                    <!-- <fa-icon [icon]="icon_dict.faUserTie"></fa-icon> -->
                    <fa-icon [icon]="['fas', 'user-tie']"></fa-icon>
                  </ng-container>

                  <ng-container *ngIf="nombreTecnico.tipo_tecnico == '2'">
                    {{ nombreTecnico.nombre_tecnico }} | Técnico
                    <!-- <i class="fa-solid fa-hammer"></i> -->
                    <fa-icon [icon]="['fas', 'hammer']"></fa-icon>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modales-->

<!--Añadir Cuadrilla-->
<div
  class="modal fade"
  id="AddCuadrilla"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Añadir Cuadrilla</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="CloseModal()"
        ></button>
      </div>
      <form
        [formGroup]="FormAddCuadrilla"
        (ngSubmit)="procesarAgregar()"
        id="FormAddCuadrilla"
        novalidate
      >
        <div class="modal-body">
          <div class="col-md-12">
            <div class="d-flex row">
              <!-- Div del nodo de cuadrillas -->
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Nombre de la Cuadrilla</strong></label
                >
                <input
                  formControlName="cuadrilla"
                  type="text"
                  name="NombreCuadrilla"
                  formControlName="n_cuadrilla"
                  class="form-control EmiInput"
                  #nombre
                />
                <div
                  class="container-error-message pt-1"
                  *ngIf="FormAddCuadrilla.get('n_cuadrilla')?.touched
                                                                           && FormAddCuadrilla.get('n_cuadrilla')?.errors?.['required']
                                                                           "
                  id="error_input"
                >
                  Es requerido este campo
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Nodo</strong></label
                >

                <!-- Cargar Nodo de las Cuadrillas -->
                <select
                  name="nodo"
                  class="form-select EmiInput"
                  #Nodo
                  formControlName="nodo"
                  (change)="consultaMDTNew(Nodo.value)"
                >
                  <option value="">Seleccione una Opción</option>
                  <ng-container *ngFor="let nodo of nodos; let i = index">
                    <option value="{{ nodo.codigo_nodo }}">
                      {{ nodo.nombre | titlecase}}
                    </option>
                  </ng-container>
                </select>
                <!-- Validar que el campo no este Vacio -->
                <div
                  class="container-error-message pt-1"
                  *ngIf="FormAddCuadrilla.get('nodo')?.touched
                                                                 && FormAddCuadrilla.get('nodo')?.errors?.['required']
                                                                 "
                  id="error_input"
                >
                  Es requerido este campo
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Buscar MDT</strong></label
                >
                <div class="d-flex flex-row">
                  <input
                    type="text"
                    name="BuscarMdt"
                    class="form-control EmiInput"
                    #buscar
                  />
                  <div
                    class="btn-buscar d-flex justify-content-center ms-3"
                    (click)="BuscarMdt(buscar.value)"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <!-- Validar que el campo no este Vacio -->
              </div>
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>MDT</strong></label
                >
                <div class="d-flex flex-row w-100">
                  <!-- Cargar Nodo de las Cuadrillas -->
                  <mat-form-field class="w-100">
                    <mat-select multiple #mdt class="form-select EmiInput" [(value)]="selectedValues">
                      <mat-option *ngFor="let mdt of listMDT" [value]="mdt.id_mdt">{{mdt.nombre}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <select

                    name="mdt"
                    class="form-select EmiInput"
                    #mdt
                  >
                    <option value="">Seleccione una Opción</option>
                    <ng-container *ngFor="let mdt of listMDT; let i = index">
                      <option value="{{ mdt.id_mdt }}">
                        {{ mdt.nombre}}
                      </option>
                    </ng-container>
                  </select> -->
                  <div>
                    <div
                      class="btn-buscar d-flex justify-content-center ms-3"
                      (click)="addMdtLote(mdt2.value, Nodo.value)"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                </div>
                </div>
                <div
                  class="container-error-message pt-1"
                  *ngIf="FormAddCuadrilla.get('mdt')?.touched
                                                           && FormAddCuadrilla.get('mdt')?.errors?.['required']
                                                           "
                  id="error_input"
                >
                  Es requerido este campo
                </div>
              </div>

              <!--Salto de linea-->
            </div>

            <div class="table-responsive scrollBlack">
              <!-- <table class="table table-fixed table-striped">
                <thead class="table-header">
                  <tr>
                    <th scope="col">N°</th>
                    <th scope="col">Cod. Nodo</th>
                    <th scope="col">Cod. Olt</th>
                    <th scope="col">Cod. Mdt</th>
                    <th scope="col">Nombre Olt</th>
                    <th scope="col">Nombre Mdt</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr>
                    <td
                      class="text-center"
                      colspan="7"
                      *ngIf="listLoteMdt == ''"
                    >
                      Sin registros
                    </td>
                  </tr>
                  <tr
                    class="trTable"
                    *ngFor="let lote of listLoteMdt; let indice = index"
                  >
                    <th scope="row">{{ indice + 1 }}</th>
                    <td>{{ lote.codigo_nodo }}</td>
                    <td>{{ lote.codigo_olt }}</td>
                    <td>{{ lote.codigo_mdt }}</td>
                    <td>{{ lote.nombre_olt }}</td>
                    <td>{{ lote.nombre }}</td>
                    <td>
                      <div class="">
                        <a
                          class="icon-table-lote"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Eliminar del lote"
                          (click)="dltMdtLote(lote.id_mdt)"
                          ><i class="fa-sharp fa-solid fa-minus"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table> -->


              <table
              mat-table
              [dataSource]="dataSourceE"
              matSort
              (matSortChange)="announceSortChange($event)"
              class="table table-striped table-fixed mat-elevation-z3"
            >
              <ng-container matColumnDef="indice">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                #
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.indice }}</td>
              </ng-container>
            
              <ng-container matColumnDef="codigo_nodo">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Codigo Nodo
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.codigo_nodo }}</td>
              </ng-container>
            
              <ng-container matColumnDef="codigo_olt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                  Codigo Olt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.codigo_olt }}</td>
              </ng-container>
              <ng-container matColumnDef="codigo_mdt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Mdt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.codigo_mdt }}</td>
              </ng-container>
            
              <ng-container matColumnDef="nombre_olt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Nombre olt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.nombre_olt }}</td>
              </ng-container>
            
              <ng-container matColumnDef="nombre">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Nombre Mdt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.nombre }}</td>
              </ng-container>
            
              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let consulta">

                  <div class="">
                    <a
                      class="icon-table-lote"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Eliminar del lote"
                      (click)="dltMdtLote(consulta.id_mdt)"
                      >
                      <fa-icon [icon]="['fas', 'minus']"></fa-icon>
                    </a>
                  </div>
                </td>
              </ng-container>
            
              <tr
                mat-header-row
                *matHeaderRowDef="[
                'indice',
                'codigo_nodo',
                'codigo_olt',
                'codigo_mdt',
                'nombre_olt',
                'nombre',
                'acciones'
                ]"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let dataSource;
                  columns: [
                  'indice',
                  'codigo_nodo',
                  'codigo_olt',
                  'codigo_mdt',
                  'nombre_olt',
                  'nombre',
                  'acciones'
                  ]
                "
              ></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" [attr.colspan]="7">
                  Sin registros
                </td>
              </tr>
            </table>
            <mat-paginator
              [pageSizeOptions]="[items]"
              showFirstLastButtons
              aria-label="Seleccionar paginas"
            >
            </mat-paginator>



            </div>
            <!-- Validar que el campo no este Vacio -->

            <!--  -->
          </div>
        </div>

        <div class="modal-footer justify-content-end gap-3">
          <button
            type="button"
            class="btn btn-Cancelar"
            data-bs-dismiss="modal"
            (click)="CloseModal()"
          >
            Cancelar
          </button>
          <button type="submit" class="btn btn-Procesar">Procesar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--Editar Cuadrilla-->

<div
  class="modal fade"
  id="EditCuadrilla"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Editar Cuadrilla</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="CloseModalEdit()"
        ></button>
      </div>
      <form
        [formGroup]="FormEditCuadrilla"
        (ngSubmit)="ProcesarEditarCuadrilla(Nodo1.value, NombreCuadrilla.value)"
        id="FormAddCuadrilla"
      >
        <div class="modal-body">
          <div class="col-md-12">
            <div class="d-flex row">
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Nombre de la Cuadrilla</strong></label
                >
                <input
                  #NombreCuadrilla
                  type="text"
                  name="NombreCuadrilla"
                  class="form-control EmiInput"
                  value="{{ editarModal.descripcion }} "
                />
                <div
                  class="container-error-message pt-1"
                  *ngIf="FormEditCuadrilla.get('NombreCuadrilla')?.touched
                                               && FormEditCuadrilla.get('NombreCuadrilla')?.errors?.['required']
                                               "
                  id="error_input"
                >
                  Es requerido este campo
                </div>
              </div>
              <!-- NODO -->
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Nodo</strong></label
                >

                <!-- Cargar Nodo de las Cuadrillas -->
                <!-- <select
                  name="Nodo"
                  class="form-select EmiInput"
                  #Nodo1
                  (change)="consultaMDTNew(Nodo1.value)"
                  value="{{ editarModal.codigo_nodo }} "
                > -->
                <select
                  name="nodo"
                  class="form-select EmiInput"
                  #Nodo1
                  (change)="consultaMDTNew(Nodo1.value)"
                >
                  <option value="">Seleccione una Opción</option>
                  <ng-container *ngFor="let nodo of nodos; let i = index">
                    <option value="{{ nodo.codigo_nodo }}">
                      {{ nodo.nombre | titlecase}}
                    </option>

                    <!-- En caso de encontrar la opcion la Precarga -->
                    <!-- <option
                      *ngIf="editarModal.codigo_nodo == nodo.codigo_nodo"
                      selected
                      value="{{ nodo.codigo_nodo }}"
                    >
                      {{ nodo.nombre }}
                    </option>

                    <option
                      *ngIf="editarModal.codigo_nodo != nodo.codigo_nodo"
                      value="{{ nodo.codigo_nodo }}"
                    >
                      {{ nodo.nombre }}
                    </option> -->
                    <!--  -->
                  </ng-container>
                </select>
                <!-- Validar que el campo no este Vacio -->
                <div
                  class="container-error-message pt-1"
                  *ngIf="FormEditCuadrilla.get('nodo')?.touched
                                   && FormEditCuadrilla.get('nodo')?.errors?.['required']
                                   "
                  id="error_input"
                >
                  Es requerido este campo
                </div>
              </div>
              <!--  -->

              <!-- MDT -->
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Buscar MDT</strong></label
                >
                <div class="d-flex flex-row">
                  <input
                    type="text"
                    name="BuscarMdt"
                    class="form-control EmiInput"
                    #buscar2
                  />
                  <div
                    class="btn-buscar d-flex justify-content-center ms-3"
                    (click)="BuscarMdt(buscar2.value)"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                </div>
                <!-- Validar que el campo no este Vacio -->
              </div>

              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>MDT</strong></label
                >
                <div class="d-flex flex-row w-100">
                  <!-- Cargar Nodo de las Cuadrillas -->
                  <!-- <mat-checkbox (change)="toggleAllSelection()">{{ isAllSelected() ? 'Deseleccionar todos' : 'Seleccionar todos' }}</mat-checkbox> -->
                  <mat-form-field class="w-100">
                    <mat-select multiple #mdt2 class="form-select EmiInput" [(value)]="selectedValues">
                      <mat-option *ngFor="let mdt of listMDT" [value]="mdt.id_mdt">{{mdt.nombre}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <select
                    name="mdt"
                    class="form-select EmiInput"

                    value=""
                  >
                    <option value="">Seleccione una Opción</option>
                    <ng-container *ngFor="let mdt of listMDT; let i = index">
                      <option value="{{ mdt.id_mdt }}">
                        {{ mdt.nombre}}
                      </option>
                    </ng-container>
                  </select> -->
                  <div>
                    <div
                    class="btn-buscar d-flex justify-content-center ms-3"
                    (click)="addMdtLote2(mdt2.value, Nodo1.value)"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="container-error-message pt-1"
                  *ngIf="FormAddCuadrilla.get('mdt')?.touched
                                                           && FormAddCuadrilla.get('mdt')?.errors?.['required']
                                                           "
                  id="error_input"
                >
                  Es requerido este campo
                </div>
              </div>
              <!--  -->
              <!--NOMBRE CUADRILLA -->
            </div>
            <div class="table-responsive scrollBlack">
              <!-- <table class="table table-fixed table-striped mb-0">
                <thead class="table-header">
                  <tr>
                    <th scope="col">N°</th>
                    <th scope="col">Cod. Nodo</th>
                    <th scope="col">Cod. Olt</th>
                    <th scope="col">Cod. Mdt</th>
                    <th scope="col">Nombre Olt</th>
                    <th scope="col">Nombre Mdt</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr>
                    <td
                      class="text-center"
                      colspan="7"
                      *ngIf="listMdtEdt == ''"
                    >
                      Sin registros
                    </td>
                  </tr>
                  <tr
                    class="trTable"
                    *ngFor="let lote of listMdtEdt; let indice = index"
                  >
                    <th scope="row">{{ indice + 1 }}</th>
                    <td>{{ lote.codigo_nodo }}</td>
                    <td>{{ lote.codigo_olt }}</td>
                    <td>{{ lote.codigo_mdt }}</td>
                    <td>{{ lote.nombre_olt }}</td>
                    <td>{{ lote.nombre }}</td>
                    <td>
                      <div class="">
                        <a
                          class="icon-table-lote"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Eliminar del lote"
                          (click)="dltMdtLote2(lote.id_mdt)"
                          >
                          <fa-icon [icon]="['fas', 'minus']"></fa-icon>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table> -->

              <table
              mat-table
              [dataSource]="dataSource"
              matSort
              (matSortChange)="announceSortChange($event)"
              class="table table-striped table-fixed mat-elevation-z3"
            >
              <ng-container matColumnDef="indice">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                #
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.indice }}</td>
              </ng-container>
            
              <ng-container matColumnDef="codigo_nodo">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Codigo Nodo
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.codigo_nodo }}</td>
              </ng-container>
            
              <ng-container matColumnDef="codigo_olt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                  Codigo Olt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.codigo_olt }}</td>
              </ng-container>
              <ng-container matColumnDef="codigo_mdt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Mdt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.codigo_mdt }}</td>
              </ng-container>
            
              <ng-container matColumnDef="nombre_olt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Nombre olt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.nombre_olt }}</td>
              </ng-container>
            
              <ng-container matColumnDef="nombre">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="Ordenar por nombre"
                >
                Nombre Mdt
                </th>
                <td mat-cell *matCellDef="let consulta">{{ consulta.nombre }}</td>
              </ng-container>
            
              <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                <td mat-cell *matCellDef="let consulta">

                  <div class="">
                    <a
                      class="icon-table-lote"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Eliminar del lote"
                      (click)="dltMdtLote2(consulta.id_mdt)"
                      >
                      <fa-icon [icon]="['fas', 'minus']"></fa-icon>
                    </a>
                  </div>
                </td>
              </ng-container>
            
              <tr
                mat-header-row
                *matHeaderRowDef="[
                'indice',
                'codigo_nodo',
                'codigo_olt',
                'codigo_mdt',
                'nombre_olt',
                'nombre',
                'acciones'
                ]"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let dataSource;
                  columns: [
                  'indice',
                  'codigo_nodo',
                  'codigo_olt',
                  'codigo_mdt',
                  'nombre_olt',
                  'nombre',
                  'acciones'
                  ]
                "
              ></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" [attr.colspan]="7">
                  Sin registros
                </td>
              </tr>
            </table>
            <mat-paginator
              [pageSizeOptions]="[items]"
              showFirstLastButtons
              aria-label="Seleccionar paginas"
            >
            </mat-paginator>




            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-Cancelar"
            (click)="CloseModalEdit()"
          >
            Cancelar
          </button>
          <button type="submit" class="btn btn-Procesar me-2">Procesar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!--Eliminar Cuadrilla-->

<div
  class="modal fade"
  id="eliminar"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-g">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Eliminar Cuadrilla</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="CloseModaleE()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="d-flex row justify-content-center">
          <button
            type="button"
            class="btn btn-Procesar me-2"
            (click)="Eliminar()"
          >
            Procesar
          </button>
          <button
            type="button"
            class="btn btn-Cancelar"
            data-bs-dismiss="modal"
            (click)="CloseModaleE()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
