<ngx-spinner type="ball-atom" bdColor="rgba(0, 0, 0, 1)"
  ><p class="loadText">Cargando...</p></ngx-spinner
>
<div class="mt-5 container-fluid contRes">
  <div class="d-flex align-items-center tituloModulo mb-2">
    <i class="fa-solid fa-person iconTitulo"></i>
    <h3>Gestión de Técnicos</h3>
  </div>
  <div class="col-md-12">
    <div class="d-flex row justify-content-between busRes">
      <div class="col-md-8 d-flex row">
        <div class="col-md-3 mb-3 mt-3">
          <input
            name="CodigoTecnico"
            type="Text"
            class="form-control EmiInput"
            #codTecnicoBuscar
            (input)="applyFilter($event)" placeholder="Nombre o Cuadrilla"
          />
        </div>
      </div>
      <!-- <div class="col-md-3 mt-4 d-flex justify-content-end addRes">
        <div>
          <input
            type="button"
            class="btn btn-Procesar"
            value="Añadir Técnico"
            (click)="openAddTecnico()"
          />
        </div>
      </div> -->
    </div>
  </div>
  <div class="col-md-12">
    <div class="table-responsive scrollBlack">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)"
        class="table table-striped table-fixed mat-elevation-z3"
      >
        <ng-container matColumnDef="codigo_tecnico">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Ordenar por nombre"
          >
            Cod. Técnicos
          </th>
          <td mat-cell *matCellDef="let user">{{ user.codigo_tecnico }}</td>
        </ng-container>
        <ng-container matColumnDef="nombre_tecnico">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Ordenar por correo"
          >
            Nombre y Apellido
          </th>
          <td mat-cell *matCellDef="let user">{{ user.nombre_tecnico }}</td>
        </ng-container>
        <ng-container matColumnDef="tipo_tecnico">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Ordenar por correo"
          >
            Tipo Técnico
          </th>
          <td mat-cell *matCellDef="let user">
            {{ user.tipo_tecnico == 1 ? "Técnico Lider" : "Técnico" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nombre_cuadrilla">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Ordenar por usuario"
          >
            Cuadrilla
          </th>
          <td mat-cell *matCellDef="let user">
            {{
              user.nombre_cuadrilla === "" ? "-" : user.nombre_cuadrilla
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nombre_aliado">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Ordenar por rol"
          >
            Aliado
          </th>
          <td mat-cell *matCellDef="let user">{{ user.nombre_aliado === "" ? "-" : user.nombre_aliado | titlecase }}</td>
        </ng-container>
        <ng-container matColumnDef="codigo_instalador">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="Ordenar por rol"
          >
            Cod. Instalador
          </th>
          <td mat-cell *matCellDef="let user">{{ user.codigo_instalador === null || user.codigo_instalador ==='' ? "-" : user.codigo_instalador }}</td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let user">
            <div>
              <a
                class="icon-table"
                (click)="openViewTecnico(user); $event.stopPropagation()"
                data-toggle="tooltip"
                data-placement="top"
                title="Ver Detalle"
              >
                <fa-icon [icon]="['fas', 'eye']"></fa-icon>
              </a>

              <a
                class="icon-table"
                (click)="openChangeTecnico(user); $event.stopPropagation()"
                data-toggle="tooltip"
                data-placement="top"
                title="Actualizar Técnico"
              >
                <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
              </a>

              <a
                class="icon-table"
                (click)="openAnularTecnico(user.id_tecnico); $event.stopPropagation()"
                data-toggle="tooltip"
                data-placement="top"
                title="Inactivar Técnico"
              >
                <fa-icon [icon]="['fas', 'user-minus']"></fa-icon>
              </a>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="[
            'codigo_tecnico',
            'nombre_tecnico',
            'tipo_tecnico',
            'nombre_cuadrilla',
            'codigo_instalador',
            'acciones'
          ]"
        ></tr>
        <tr
          mat-row
          (click)="openViewTecnico(dataSource)"
          *matRowDef="
            let dataSource;
            columns: [
              'codigo_tecnico',
              'nombre_tecnico',
              'tipo_tecnico',
              'nombre_cuadrilla',
              'codigo_instalador',
              'acciones'
            ]
          "
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[13]"
        showFirstLastButtons
        aria-label="Seleccionar paginas"
      >
      </mat-paginator>
    </div>
  </div>
</div>

<!--Añadir Tecnico-->

<div
  class="modal fade"
  id="AddTecnico"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Añadir Técnico</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
        [formGroup]="procesarFrom"
        (ngSubmit)="
          saveAddTecnico(
            nombre.value,
            sucursal.value,
            telefono.value,
            correo.value,
            tipoTecnico.value,
            codProveedor.value,
            codInstalador.value,
            codSupervisor.value
          )
        "
        id="FormDocumento"
        novalidate
      >
        <div class="modal-body">
          <div class="col-md-12">
            <div class="d-flex row">
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Nombre y Apellido</strong></label
                >
                <input
                  type="text"
                  name="nombre"
                  class="form-control EmiInput"
                  #nombre
                />
                <div
                  class="container-error-message"
                  *ngIf="procesarFrom.get('nombre')?.touched
                      && procesarFrom.get('nombre')?.errors?.['required']
                      "
                  id="error_input"
                >
                  El nombre es requerido
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Sucursal</strong></label
                >
                <select name="sucursal" class="form-control EmiInput" #sucursal>
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let list of listCuadrilla"
                    [value]="list.codigo_sucursal"
                  >
                    {{ list.descripcion }}
                  </option>
                </select>
                <div
                  class="container-error-message"
                  *ngIf="procesarFrom.get('sucursal')?.touched
                    && procesarFrom.get('sucursal')?.errors?.['required']
                    "
                  id="error_input"
                >
                  Seleccione una sucursal
                </div>
              </div>
            </div>
            <!--Salto de linea-->
            <div class="d-flex row">
              <div class="col-md-4 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Télefono</strong></label
                >
                <input
                  type="number"
                  name="telefono"
                  class="form-control EmiInput"
                  #telefono
                />
                <div
                  class="container-error-message"
                  *ngIf="procesarFrom.get('telefono')?.touched
                      && procesarFrom.get('telefono')?.errors?.['required']
                      "
                  id="error_input"
                >
                  Ingrese un numero telefonico
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Correo</strong></label
                >
                <input
                  type="mail"
                  name="correo"
                  class="form-control EmiInput"
                  #correo
                />
                <div
                  class="container-error-message"
                  *ngIf="procesarFrom.get('correo')?.touched
                      && procesarFrom.get('correo')?.errors?.['required']
                      "
                  id="error_input"
                >
                  Ingrese un correo
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Tipo Técnico</strong></label
                >
                <select
                  name="tipoTecnico"
                  class="form-control EmiInput"
                  #tipoTecnico
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let list2 of listTipoTecnico"
                    [value]="list2.id_tipo_tecnico"
                  >
                    {{ list2.descripcion }}
                  </option>
                </select>
                <div
                  class="container-error-message"
                  *ngIf="procesarFrom.get('correo')?.touched
                    && procesarFrom.get('correo')?.errors?.['required']
                    "
                  id="error_input"
                >
                  Seleccione un tipo de tecnico
                </div>
              </div>
            </div>
            <!--Salto de linea-->
            <div class="d-flex row">
              <div class="col-md-2 mb-3 justify-content-center">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>¿Aliado?</strong></label
                >
                <div
                  class="d-flex justify-content-center form-switch checkboxesp"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    name="RememberMe"
                    value="Yes"
                    (click)="DisabledProveedor()"
                  />
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Seleccione Aliado</strong></label
                >
                <select
                  name="codProveedor"
                  class="form-control EmiInput"
                  [disabled]="disable"
                  #codProveedor
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let obj of listAliados"
                    [value]="obj.id_aliado"
                  >
                    {{ obj.nombre }}
                  </option>
                </select>
                <div
                  class="container-error-message"
                  *ngIf="procesarFrom.get('codProveedor')?.touched
                      && procesarFrom.get('codProveedor')?.errors?.['required']
                      "
                  id="error_input"
                >
                  Ingrese codigo de proveedor
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Cod. Instalador</strong></label
                >
                <input
                  type="text"
                  name="instalador"
                  class="form-control EmiInput"
                  [disabled]="disable2"
                  #codInstalador
                />
              </div>
              <div class="col-md-4 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Código Supervisor</strong></label
                >
                <input
                  type="number"
                  name="codSupervisor"
                  class="form-control EmiInput"
                  #codSupervisor
                />
                <div
                  class="container-error-message"
                  *ngIf="(procesarFrom.get('codSupervisor')?.touched
                      && procesarFrom.get('codSupervisor')?.errors?.['required'])
                      "
                  id="error_input"
                >
                  Ingrese codigo de supervisor
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-Cancelar"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>

          <button type="submit" class="btn btn-Procesar me-2">Procesar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Editar Tecnico -->

<div
  class="modal fade"
  id="changeTecnico"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">
          Actualizar Técnico: {{ datosUpdate.nombre_tecnico }}
        </h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form
        id="changeFrom"
        [formGroup]="changeFrom"
        (ngSubmit)="
          saveChangeTecnico(
            nombreChange.value,
            sucursalChange.value,
            telefonoChange.value,
            correoChange.value,
            tipoTecnicoChange.value,
            codInstaladorChange.value
          )
        "
        novalidate
      >
        <div class="modal-body">
          <div class="col-md-12">
            <div class="d-flex row">
              <div class="col-md-6 mb-3 d-none">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Nombre y Apellido</strong></label
                >
                <input
                  type="text"
                  name="nombreC"
                  class="form-control EmiInput"
                  formControlName="nombreC"
                  #nombreChange
                  readonly
                />
                <!-- <div
                  class="container-error-message"
                  *ngIf="changeFrom.get('nombreC')?.touched
                      && changeFrom.get('nombreC')?.errors?.['required']
                      "
                  id="error_input"
                >
                  El Nombre es requerido
                </div> -->
              </div>
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Sucursal</strong></label
                >
                <select
                  name="sucursalC"
                  class="form-control EmiInput"
                  formControlName="sucursalC"
                  #sucursalChange
                >
                  <option value="">Seleccione</option>

                  <option
                    *ngFor="let list of listCuadrilla"
                    [value]="list.codigo_sucursal"
                  >
                    {{ list.descripcion | titlecase }}
                  </option>
                </select>
                <div
                  class="container-error-message"
                  *ngIf="changeFrom.get('sucursalC')?.touched
                    && changeFrom.get('sucursalC')?.errors?.['required']
                    "
                  id="error_input"
                >
                  Seleccione una sucursal
                </div>
              </div>

              <!--Salto de linea-->

              <div class="col-md-6 mb-3 d-none">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Télefono</strong></label
                >
                <input
                  type="number"
                  name="telefonoC"
                  class="form-control EmiInput"
                  formControlName="telefonoC"
                  #telefonoChange
                  readonly
                />
                <!-- <div
                  class="container-error-message"
                  *ngIf="changeFrom.get('telefonoC')?.touched
                      && changeFrom.get('telefonoC')?.errors?.['required']
                      "
                  id="error_input"
                >
                  Ingrese un numero telefonico
                </div> -->
              </div>
              <div class="col-md-6 mb-3 d-none">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Correo</strong></label
                >
                <input
                  type="mail"
                  name="correoC"
                  class="form-control EmiInput"
                  formControlName="correoC"
                  #correoChange
                  readonly
                />
                <!-- <div
                  class="container-error-message"
                  *ngIf="changeFrom.get('correoC')?.touched
                      && changeFrom.get('correoC')?.errors?.['required']
                      "
                  id="error_input"
                >
                  Ingrese un correo
                </div> -->
              </div>
              <div class="col-md-6 mb-3">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Tipo Técnico</strong></label
                >
                <select
                  name="tipoTecnicoC"
                  class="form-control EmiInput"
                  formControlName="tipoTecnicoC"
                  #tipoTecnicoChange
                  (change)="changeTecnicoCodigo(tipoTecnicoChange.value)"
                >
                  <option value="">Seleccione</option>
                  <option
                    *ngFor="let list2 of listTipoTecnico"
                    [value]="list2.id_tipo_tecnico"
                  >
                    {{ list2.descripcion }}
                  </option>
                </select>
                <div
                  class="container-error-message"
                  *ngIf="changeFrom.get('tipoTecnicoC')?.touched
                    && changeFrom.get('tipoTecnicoC')?.errors?.['required']
                    "
                  id="error_input"
                >
                  Seleccione un tipo de tecnico
                </div>
              </div>
              <div class="col-md-6 mb-3" id="eye">
                <label for="exampleInputEmail1" class="form-label"
                  ><strong>Cod. Instalador</strong></label
                >
                <input
                  type="text"
                  name="codInstalador"
                  class="form-control EmiInput"
                  formControlName="codInstalador"
                  #codInstaladorChange
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-Cancelar"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>

          <button type="submit" class="btn btn-Procesar me-2">Procesar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Detalle Tecnico -->
<div
  class="modal fade"
  id="viewTecnico"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Detalle Técnico</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="d-flex row">
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Código Técnico: </strong>
                {{ detalle.codigoTecnico }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Nombre y Apellido: </strong>
                {{ detalle.nombre }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Tipo Técnico: </strong>
                {{ detalle.nombre == "1" ? "Técnico Lider" : "Técnico" }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Sucursal: </strong>
                {{ detalle.sucursal | titlecase }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Télefono: </strong>
                {{ detalle.telefono }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Correo: </strong>
                {{ detalle.correo }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Cuadrilla: </strong>
                {{ detalle.cuadrilla }}
              </h6>
            </div>
            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Código Supervisor: </strong>
                {{ detalle.codigoSupervisor }}
              </h6>
            </div>

            <div class="col-md-6">
              <h6 class="font-detalle">
                <strong class="strong">Aliado: </strong>
                {{ detalle.nombre_aliado | titlecase }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-Procesar me-2"
          (click)="saveViewTecnico()"
        >
          Aceptar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Eliminar Tecnico -->

<div
  class="modal fade"
  id="anularTecnico"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-g">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Inactivar Técnico</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="d-flex row justify-content-center gap-3">
          <button
            type="button"
            class="btn btn-Cancelar"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-Procesar"
            (click)="saveAnularTecnico()"
          >
            Procesar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
