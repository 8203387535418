<ngx-spinner type="ball-atom" bdColor="rgba(0, 0, 0, 0.9)"
  ><p class="loadText">Cargando...</p></ngx-spinner
>
<div class="container-fluid">
  <div class="left d-flex justify-content-center">
    <!-- <canvas></canvas> -->

    <div class="" id="texto">
      <div>
        <P class="pLogin">¡Bienvenido!</P>
        <h1 class="tituloLogin">
          Sistema Integrado <br /> de 
          Gestión Operativa
        </h1>
      </div>
    </div>
  </div>

  <!-- Right -->

  <div class="right">
    <div class="card border-0 rounded-5 my-5" id="login1">
      <div class="card-body">
        <!-- Modo Texto : SIGO -->

        <!-- <h5 class="card-title text-center mb-3 fw-light fs-7" >
                <h1 class="text-center mb-0 text-primary" style="font-size:6vh ;">
                  <strong>S.I.G.O</strong></h1></h5> -->

        <!-- Logo de Arriba -->
        <div
          class="mb-3 d-flex justify-content-center align-items-center flex-column"
          id="logo"
        >
          <img [src]="ImagePath.logo_new2" id="airtek_logo" />
        </div>
        <!--  -->

        <form
          [formGroup]="LoginForm"
          (ngSubmit)="onSubmit()"
          id="FormLogin"
          novalidate
        >
          <!-- Inputs del Login Div -->
          <div id="form_login">
            <div class="col-md-12 mt-4">
              <fa-icon [icon]="['fas', 'user']" class="iconLogin"></fa-icon>
              <input
                type="text"
                name="username"
                required
                class="form-control inputLogin"
                id="userInput"
                aria-describedby="emailHelp"
                placeholder="Usuario"
                formControlName="username"
                autocomplete="off"
                #userInput
              />

              <!-- Validar que el campo no este Vacio -->
              <div
                class="container-error-message"
                *ngIf="LoginForm.get('username')?.touched
                                      && LoginForm.get('username')?.errors?.['required']
                                      "
                id="error_input"
              >
                Es requerido este campo
              </div>

              <!-- Validacion de usuario LOGIN  -->
              <p
                id="error_p"
                *ngIf="LoginForm.get('username')?.errors?.['pattern'] 
                         || LoginForm.get('username')?.hasError('message')"
              >
                Usuario inválido
              </p>
            </div>

            <div class="col-md-12 mt-g2">
              <div class="d-flex align-items-center">
                <fa-icon
                  [icon]="['fas', 'lock']"
                  class="iconLogin iconPass"
                ></fa-icon>
                <input
                  [type]="tipo"
                  name="password"
                  minlength="6"
                  class="form-control inputLogin"
                  id="exampleInputPassword1"
                  placeholder="Contraseña"
                  formControlName="password"
                  #pass
                />
                <span class="caseEye">
                  <fa-icon
                    [icon]="['fas', 'eye']"
                    class="iconEye"
                    (click)="mostrarContrasena()"
                    id="eye"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'eye-slash']"
                    class="iconEye d-none"
                    (click)="mostrarContrasena()"
                    id="eyeSlash"
                  ></fa-icon>
                </span>
              </div>
              <!-- <fa-icon [icon]="['fas', 'eye']" class="iconEye" (click) = "mostrarContrasena()" id="eye"></fa-icon>
              <fa-icon
              [icon]="['fas', 'eye-slash']"
              class="iconEye d-none"
              (click)="mostrarContrasena()"
              id="eyeSlash"
            ></fa-icon> -->
              <!-- Validar que el campo no este Vacio -->
              <div
                class="container-error-message pt-1"
                *ngIf="LoginForm.get('password')?.touched
                                      && LoginForm.get('password')?.errors?.['required']
                                      "
                id="error_input"
              >
                Es requerido este campo
              </div>

              <!-- Validar minimo de contraseña -->
              <div
                class="container-error-message"
                *ngIf="LoginForm.get('password')?.touched && LoginForm.get('password')?.errors?.['minlength']
                         "
                id="error_input"
              >
                Ingrese una contraseña mayor a seis (06) digitos
              </div>
            </div>
          </div>
          <!-- Fin -->

          <div class="mb-3 form-check form-switch mx-auto mt-g d-flex gap-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              name="RememberMe"
              value="Yes"
              #checkedInput
            />
            <label
              class="form-check-label ml-10 mt-1"
              for="flexSwitchCheckChecked"
            >
              <strong class="">¡Recuerdame!</strong></label
            >
          </div>
          <div
            class="justify-content-center align-items-center d-grid d-flex mt-1 mx-auto"
          >
            <div class="d-grid gap-2 w-100">
              <button
                class="btn btn-g btn-lg"
                type="submit"
                [disabled]="!LoginForm.valid"
              >
                <!-- <img
                  [src]="ImagePath.logo_flecha"
                  class=""
                  style="width: 22%"
                /> -->
                <p class="ng">Iniciar Sesión</p>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
