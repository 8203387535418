import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { Router } from '@angular/router';
import { GestionCuadrillaService } from '../../../../services/operaciones/averias-menores/gestion-cuadrilla.service';
import { EmisionService } from '../../../../services/operaciones/averias-menores/emision.service';
import { AveriaServiceService } from '../../../../services/operaciones/averias-menores/HomeAveria-service.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../services/user/user.service';

//Modulo de Formularios
import { FormBuilder, Validators } from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import {LiveAnnouncer} from '@angular/cdk/a11y';

declare var window: any;

@Component({
  selector: 'app-gestion-tecnicos',
  templateUrl: './gestion-tecnicos.component.html',
  styleUrls: ['./gestion-tecnicos.component.css'],
})
export class GestionTecnicosComponent implements AfterViewInit {
  @ViewChild('nombre') valueNombre!: ElementRef<HTMLSelectElement>;
  @ViewChild('sucursal') valueSucursal!: ElementRef<HTMLSelectElement>;
  @ViewChild('telefono') valueTelefono!: ElementRef<HTMLSelectElement>;
  @ViewChild('correo') valueCorreo!: ElementRef<HTMLSelectElement>;
  @ViewChild('tipoTecnico') valueTipo!: ElementRef<HTMLSelectElement>;
  @ViewChild('codProveedor') valueProveedor!: ElementRef<HTMLSelectElement>;
  @ViewChild('codSupervisor') valueSupervisor!: ElementRef<HTMLSelectElement>;
  @ViewChild('codInstalador') valueInstalador!: ElementRef<HTMLSelectElement>;

  @ViewChild('nombreChange') valueNombreChange!: ElementRef<HTMLSelectElement>;
  @ViewChild('sucursalChange')
  valueSucursalChange!: ElementRef<HTMLSelectElement>;
  @ViewChild('telefonoChange')
  valueTelefonoChange!: ElementRef<HTMLSelectElement>;
  @ViewChild('correoChange') valueCorreoChange!: ElementRef<HTMLSelectElement>;
  @ViewChild('tipoTecnicoChange')
  valueTipoChange!: ElementRef<HTMLSelectElement>;
  @ViewChild('codInstaladorChange') codInstaladorChange!: ElementRef<HTMLSelectElement>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  AddTecnico: any;
  viewTecnico: any;
  listTecnicos: any;
  listTecnicosOld: any;
  anularTecnico: any;
  changeTecnico: any;
  idTecnico: any;
  formProcesar: any = {};
  formChange: any = {};
  datosUpdate: any = {};
  listCuadrilla: any;
  listTipoTecnico: any;
  listAliados: any;
  disable: boolean = true;
  disable2: boolean = false;
  message = '';
  titleMessage = '';
  detalle = {
    codigoTecnico: '',
    nombre: '',
    tipoTecnico: '',
    sucursal: '',
    codigoSupervisor: '',
    telefono: '',
    correo: '',
    nombre_aliado: '',
    cuadrilla: '',
  };

  clickedRow = new Set()
  // @ViewChild('loading') loading!: ElementRef;

  constructor(
    protected tokenStorage: TokenStorageService,
    protected router: Router,
    protected toastr: ToastrService,
    protected fb: FormBuilder,
    protected renderer: Renderer2,
    protected spinner: NgxSpinnerService,

    // Servicios Exportados
    protected GestionCuadrillaService: GestionCuadrillaService,
    protected EmisionService: EmisionService,
    protected userService: UserService,
    protected HomeAveriasService: AveriaServiceService,

    private _liveAnnouncer: LiveAnnouncer,
  ) {}

  ngOnInit():void{
    this.cargarDatosAdicional();
  }

  ngAfterViewInit(): void {
  
    //Añadir Tecnico
    this.AddTecnico = new window.bootstrap.Modal(
      document.getElementById('AddTecnico')
    );

    this.viewTecnico = new window.bootstrap.Modal(
      document.getElementById('viewTecnico')
    );

    this.anularTecnico = new window.bootstrap.Modal(
      document.getElementById('anularTecnico')
    );

    this.changeTecnico = new window.bootstrap.Modal(
      document.getElementById('changeTecnico')
    );

    this.cargarDatos();
    this.cargarAliados();
  }


  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
  
    if (filterValue != '') {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const dataStr = data.nombre_tecnico.toLowerCase() + data.nombre_cuadrilla.toLowerCase();
        return dataStr.indexOf(filter) !== -1;
      };
      this.dataSource.filter = filterValue.trim().toLowerCase();
    } else {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.active === filter;
      };
    }
  }



  procesarFrom = this.fb.group(
    {
      nombre: ['', [Validators.required]],
      sucursal: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      correo: ['', [Validators.required]],
      tipoTecnico: ['', [Validators.required]],
      codProveedor: [{ disabled: true, value: '' }, [Validators.required]],
      codInstalador:[{disabled: true, value: ''}],
      codSupervisor: ['', [Validators.required]],
    },
    { updateOn: 'blur' }
  );

  openAddTecnico() {
    this.valueNombre.nativeElement.value = '';
    this.valueCorreo.nativeElement.value = '';
    this.valueProveedor.nativeElement.value = '';
    this.valueSucursal.nativeElement.value = '';
    this.valueSupervisor.nativeElement.value = '';
    this.valueTelefono.nativeElement.value = '';
    this.valueTipo.nativeElement.value = '';
    this.valueInstalador.nativeElement.value = '';

    this.AddTecnico.show();
  }

  saveAddTecnico(
    nombre: any,
    sucursal: any,
    telefono: any,
    correo: any,
    tipoTecnico: any,
    codProveedorAdd: any,
    codInstalador:any,
    codSupervisor: any
  ) {
    this.procesarFrom.setValue({
      nombre: nombre,
      sucursal: sucursal,
      telefono: telefono,
      correo: correo,
      tipoTecnico: tipoTecnico,
      codProveedor: codProveedorAdd,
      codInstalador: codInstalador,
      codSupervisor: codSupervisor,
    });

    this.procesarFrom.markAllAsTouched();

    if (this.procesarFrom.valid) {
      this.formProcesar = {
        codSucursal: this.procesarFrom.get('sucursal')?.value,
        nombre: this.procesarFrom.get('nombre')?.value,
        telefono: this.procesarFrom.get('telefono')?.value,
        correo: this.procesarFrom.get('correo')?.value,
        codigoProveedor: this.procesarFrom.get('codProveedor')?.value,
        codInstalador: this.procesarFrom.get('codInstalador')?.value,
        codigoSupervisor: this.procesarFrom.get('codSupervisor')?.value,
        tipoTecnico: this.procesarFrom.get('tipoTecnico')?.value,
        usuario: this.tokenStorage.getUser(),
      };

      this.GestionCuadrillaService.postAddTecnico(this.formProcesar).subscribe({
        next: (data) => {
          //
          const responseProcesar = data;

          if (responseProcesar.statusMensajes=='2'){
            this.titleMessage = responseProcesar.descripcion;
            this.message = responseProcesar.detalle;
            this.toastr.error(this.message, this.titleMessage, {
              timeOut: 5000,
            });
          }else{

            this.titleMessage = responseProcesar.descripcion;
            this.message = responseProcesar.detalle;
            this.toastr.success(this.message, this.titleMessage, {
              timeOut: 5000,
            });
          }
          setTimeout(() => {
            this.cargarDatos();
          }, 400);
        },
        error: (err) => {
          //
          this.titleMessage = err.error.descripcion;
          this.message = err.error.detalle;
          this.toastr.error(err.error.detalle, this.titleMessage);
        },
      });
      setTimeout(() => {
        this.cargarDatos();
        this.AddTecnico.hide();
      }, 200);
    }
  }

  openViewTecnico(consulta: any) {

    this.detalle.codigoTecnico = consulta.codigo_tecnico;
    this.detalle.nombre = consulta.nombre_tecnico;
    this.detalle.tipoTecnico = consulta.tipo_tecnico;
    this.detalle.sucursal = consulta.nombre_sucursal;
    this.detalle.codigoSupervisor = consulta.codigo_supervisor;
    this.detalle.telefono = consulta.telefono;
    this.detalle.correo = consulta.mail;
    this.detalle.nombre_aliado = consulta.nombre_aliado;
    this.detalle.cuadrilla = consulta.nombre_cuadrilla;
    this.idTecnico = consulta.id_tecnico;
    this.viewTecnico.show();
  }
  saveViewTecnico() {
    this.viewTecnico.hide();
  }

  openAnularTecnico(idTecnico: any) {
    this.idTecnico = idTecnico;
    this.anularTecnico.show();
  }
  saveAnularTecnico() {
    this.GestionCuadrillaService.postDeleteTecnico(this.idTecnico).subscribe({
      next: (data) => {
        //
        const responseProcesar = data;
        this.titleMessage = responseProcesar.descripcion;
        this.message = responseProcesar.detalle;
        this.toastr.success(this.message, this.titleMessage, {
          timeOut: 5000,
        });
        setTimeout(() => {
          this.cargarDatos();
          this.anularTecnico.hide();
        }, 400);
      },
      error: (err) => {
        //
        this.titleMessage = err.error.descripcion;
        this.message = err.error.detalle;
        this.toastr.error(err.error.detalle, this.titleMessage);
      },
    });
    setTimeout(() => {
      this.cargarDatos();
      this.anularTecnico.hide();
    }, 200);
  }

  changeFrom = this.fb.group(
    {
      // nombreC: ['', [Validators.required]],
      sucursalC: ['', [Validators.required]],
      // telefonoC: ['', [Validators.required]],
      // correoC: ['', [Validators.required]],
      tipoTecnicoC: ['', [Validators.required]],
      codInstaladorC:['',]
    },
    { updateOn: 'blur' }
  );

  openChangeTecnico(list: any) {
    // limpieza de campos
    this.valueNombreChange.nativeElement.value = '';
    this.valueSucursalChange.nativeElement.value = '';
    this.valueTelefonoChange.nativeElement.value = '';
    this.valueTipoChange.nativeElement.value = '';
    this.valueCorreoChange.nativeElement.value = '';
    this.codInstaladorChange.nativeElement.value = '';


    //toma de valores
    this.datosUpdate = list;
    this.datosUpdate.codSucursal = parseInt(list.codigo_sucursal);
    
    this.idTecnico = this.datosUpdate.id_tecnico;
    this.valueNombreChange.nativeElement.value = this.datosUpdate.nombre_tecnico;
    this.valueSucursalChange.nativeElement.value = this.datosUpdate.codSucursal;
    this.valueTelefonoChange.nativeElement.value = this.datosUpdate.telefono;
    this.valueTipoChange.nativeElement.value = this.datosUpdate.tipo_tecnico;
    this.valueCorreoChange.nativeElement.value = this.datosUpdate.mail;
    this.codInstaladorChange.nativeElement.value = this.datosUpdate.codigo_instalador;
    

    const eye: any = document.getElementById('eye');
    eye.classList.remove('d-none');

    if (this.datosUpdate.id_aliado != null){
      eye.classList.add('d-none');
    }

    if (this.datosUpdate.id_aliado == null && this.datosUpdate.tipo_tecnico ==2){
      eye.classList.add('d-none');
    }
    //show de modal
    this.changeTecnico.show();
  }

  changeTecnicoCodigo(param:any){
    const eye: any = document.getElementById('eye');
    if(param == 2 || param ==''){
      eye.classList.add('d-none');
    }
    else{
      eye.classList.remove('d-none');
    }

  }


  saveChangeTecnico(
    nombre: any,
    sucursal: any,
    telefono: any,
    correo: any,
    tipoTecnico: any,
    codInstalador: any
  ) {
    this.changeFrom.setValue({
      // nombreC: nombre,
      sucursalC: sucursal,
      // telefonoC: telefono,
      // correoC: correo,
      tipoTecnicoC: tipoTecnico,
      codInstaladorC: codInstalador
    });

    this.procesarFrom.markAllAsTouched();

    if (this.changeFrom.valid) {
      this.formChange = {
        idTecnico: this.idTecnico,
        codSucursal: this.changeFrom.get('sucursalC')?.value,
        nombre: nombre,
        telefono: telefono,
        correo: correo,
        tipoTecnico: this.changeFrom.get('tipoTecnicoC')?.value,
        codInstalador: this.changeFrom.get('codInstaladorC')?.value,
        usuario: this.tokenStorage.getUser(),
      };

      this.GestionCuadrillaService.postChangeTecnico(this.formChange).subscribe(
        {
          next: (data) => {
            //
            const responseProcesar = data;
            this.titleMessage = responseProcesar.descripcion;
            this.message = responseProcesar.detalle;
            //this.cargarDatos();
            if (responseProcesar.statusMensajes == '1') {
              this.toastr.success(this.message, this.titleMessage, {
                timeOut: 2000,
              })
              setTimeout(() => {
                this.cargarDatos();
                this.changeTecnico.hide();
              }, 200);
            } else {
              this.toastr.warning(this.message, this.titleMessage, {
                timeOut: 4000,
              })
            }
            // setTimeout(() => {
            //   this.cargarDatos();
            // }, 400);
          },
          error: (err) => {
            //
            this.titleMessage = err.error.descripcion;
            this.message = err.error.detalle;
            this.toastr.error(err.error.detalle, this.titleMessage);
          },
        }
      );

    }
  }

  DisabledProveedor() {
    if (this.disable === true) {
      this.disable = false;
      this.disable2 = true;

    } else {
      this.disable = true;
      this.disable2 = false;
    }
  }
  dataSource: any;
  cargarDatos(): void {
    this.GestionCuadrillaService.getListTecnicos().subscribe({
      next: (data) => {
        // this.hideloader();
        this.listTecnicos = data;

        this.listTecnicosOld = data;

        this.dataSource = new MatTableDataSource(this.listTecnicos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;


      },
      error: (err) => {
        // this.hideloader();
        this.toastr.error('Error en consulta de tecnicos');
      },
    });
  }

  cargarAliados(): void {
    this.GestionCuadrillaService.getListaAliados().subscribe({
      next: (data) => {
        // this.hideloader();
        this.listAliados = data;

      },
      error: (err) => {
        // this.hideloader();
        this.toastr.error('Error en consulta de tecnicos');
      },
    });
  }

  cargarDatosAdicional(): void {
    this.GestionCuadrillaService.getListSucursal().subscribe({
      next: (data) => {
        this.listCuadrilla = data;

      },
      error: (err) => {
        this.toastr.error('Error de carga');
      },
    });

    this.GestionCuadrillaService.getListTipoTecnicos().subscribe({
      next: (data) => {
        this.listTipoTecnico = data;
      },
      error: (err) => {
        this.toastr.error('Error en carga');
      },
    });
  }

  //Funcion a llamar Para Buscar
  buscar(codTecnico: any, nombreTecnico: any): any {
    if (codTecnico == '' && nombreTecnico == '') {
      this.cargarDatos();
    } else {
      if (codTecnico != '' && nombreTecnico == '') {
        this.BuscarConTecnico(this.listTecnicos, codTecnico);
      } else {
        if (codTecnico == '' && nombreTecnico != '') {
          this.BuscarConNombre(this.listTecnicos, nombreTecnico);
        } else {
          // this.BuscarConTodo(this.listTecnicos,codTecnico,nombreTecnico)
          this.toastr.error('Buscar con Codigo Tecnico o Nombre');
        }
      }
    }
  }

  // Buscar al Precionar Enter
  KeypressEnter(codTecnico: any, nombreTecnico: any): any {
    // this.CargarAsignadas()
    if (codTecnico == '' && nombreTecnico == '') {
      this.cargarDatos();
    } else {
      if (codTecnico != '' && nombreTecnico == '') {
        this.BuscarConTecnico(this.listTecnicos, codTecnico);
      } else {
        if (codTecnico == '' && nombreTecnico != '') {
          this.BuscarConNombre(this.listTecnicos, nombreTecnico);
        } else {
          // this.BuscarConTodo(this.listTecnicos,codTecnico,nombreTecnico)
          this.toastr.error('Buscar con Codigo Tecnico o Nombre');
        }
      }
    }
  }

  BuscarConTecnico(obj: any, parm: string): any {
    if (parm == '') {
      this.listTecnicos = this.listTecnicosOld;
    } else {
      let listTecnicosNew: any = [];
      let busqueda: any = this.listTecnicos.filter((element: any) =>
        element.codigo_tecnico
          .toString()
          .toUpperCase()
          .includes(parm.toString().toUpperCase())
      );

      busqueda.forEach((element: any) => {
        listTecnicosNew.push(element);
      });
      this.listTecnicos = {};
      this.listTecnicos = listTecnicosNew;
    }
  }

  BuscarConNombre(obj: any, parm: string): any {
    if (parm == '') {
      this.listTecnicos = this.listTecnicosOld;
    } else {
      let listTecnicosNew: any = [];
      let busqueda: any = this.listTecnicos.filter((element: any) =>
        element.nombre_tecnico
          .toUpperCase()
          .includes(parm.toString().toUpperCase())
      );

      busqueda.forEach((element: any) => {
        listTecnicosNew.push(element);
      });
      this.listTecnicos = {};
      this.listTecnicos = listTecnicosNew;
    }
  }

  BuscarConTodo(obj: any, parm: string, nombre: string): any {
    for (var key in obj) {
      var val0 = obj[key].codigo_tecnico;
      var val = obj[key].nombre_tecnico;
      if (parm == val0 && nombre == val) {
        // JSON.stringify(obj[key], null, 4);
        this.listTecnicos = [];
        this.listTecnicos.push(obj[key]);
        break;
      } else {
        this.listTecnicos = [];
      }
    }
  }

  // hideloader() {
  //   // Setting display of spinner
  //   // element to none
  //   this.renderer.addClass(this.loading.nativeElement, 'd-none');
  // }
}
