import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { AuthInterceptor } from './utility/auth.interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modulos de la Pagina
import { NavbarComponent } from './partials/navbar/navbar.component';

import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { MenuSidenavComponent } from './partials/menu-sidenav/menu-sidenav.component';

// Notificaciones Toastr
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
// Servicio de averias-menores
import { AveriaServiceService } from './services/operaciones/averias-menores/HomeAveria-service.service';
//Material
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GoogleMapsModule } from '@angular/google-maps';
import {MatMenuModule} from '@angular/material/menu';

// NGX dialog module
import {
  ConfirmBoxConfigModule,
  DialogConfigModule,
  NgxAwesomePopupModule,
  ToastNotificationConfigModule,
} from '@costlydeveloper/ngx-awesome-popup';
// Iconos FontAwesome
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

import {
  faHammer,
  faSquareMinus,
  faUserTie,
  faEye,
  faPencil,
  faUserMinus,
  faRepeat,
  faBan,
  faRotateRight,
  faMagnifyingGlass,
  faCircleXmark,
  faCheck,
  faEnvelope,
  faLock,
  faUser,
  faEyeSlash,
  faKey,
  faImage,
  faUsersGear,
  faCalendar,
  faNoteSticky,
  faServer,
  faWifi,
  faMinus,
  faPenToSquare,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import { RegistrarUsuariosComponent } from './modules/administrador/usuarios/registrar-usuarios/registrar-usuarios.component';
import { GestionarUsuariosComponent } from './modules/administrador/usuarios/gestionar-usuarios/gestionar-usuarios.component';
import { AveriasMenoresModule } from './modules/operaciones/averias-menores/averias-menores.module';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from './material.module';
import { MantPlantaExternaModule } from './modules/mant-planta-externa/mant-planta-externa.module';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { LightgalleryModule } from 'lightgallery/angular';
import { StatusServiceComponent } from './modules/operaciones/components/status-service/status-service.component';

import { registerLocaleData } from "@angular/common";

// Configuracion del locale de la app
import localesEsVE from '@angular/common/locales/es-VE'
registerLocaleData( localesEsVE );

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    HomeLayoutComponent,
    MenuSidenavComponent,
    RegistrarUsuariosComponent,
    GestionarUsuariosComponent,

    ],
  imports: [
    MaterialModule,
    BrowserModule,
    DragDropModule,
    MatSlideToggleModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 3,
      progressBar: true,
      titleClass: '',
      messageClass: 'Cuerpo',
    }),
    NgxSpinnerModule.forRoot({ type: 'ball-atom' }),
    GoogleMapsModule,
    // Componentes para Cuadros de dialogo
    NgxAwesomePopupModule.forRoot({}),
    DialogConfigModule.forRoot(),
    ConfirmBoxConfigModule.forRoot(),
    ToastNotificationConfigModule.forRoot(),
    FontAwesomeModule,
    // AveriasMenoresModule,
    // MantPlantaExternaModule,
    MatMenuModule,
    MatIconModule,
    NgxPaginationModule,
    LightgalleryModule
  ],
  exports:[

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    AveriaServiceService,
    {
      provide: LOCALE_ID, useValue: 'es-VE'
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Exportar iconos a todos los modulos
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faHammer,
      faSquareMinus,
      faUserTie,
      faEye,
      faPencil,
      faUserMinus,
      faRepeat,
      faBan,
      faRotateRight,
      faMagnifyingGlass,
      faCircleXmark,
      faCheck,
      faEnvelope,
      faLock,
      faUser,
      faEyeSlash,
      faKey,
      faImage,
      faUsersGear,
      faCalendar,
      faNoteSticky,
      faWifi,
      faMinus,
      faPenToSquare,
      faCircle,
    );
  }
}
