import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api } from '../../API';

const ip_api = Api.ruta;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class GestinarUsuariosService {
  constructor(private http: HttpClient) {}

  //Generar User

  InsertUser(user: any): Observable<any> {
    return this.http.post(
      ip_api + '/auth/signup',
      {
        nombre: user.nombre,
        apellido: user.apellido,
        email: user.correo,
        role: [user.role],
        password: user.pass,
      },
      { responseType: 'json' }
    );
  }

  //Consulta de Roles
  getRoles(): Observable<any> {
    return this.http.get(ip_api + '/airtek/roles/listarRoles', {});
  }
  getUser(): Observable<any> {
    return this.http.get(ip_api + '/users/allusers', {});
  }

  getListTecnicos(): Observable<any> {
    return this.http.get(ip_api + '/airtek/tecnicos/listarLiderTecnicos', {});
  }

  asosiar(list: any): Observable<any> {
    return this.http.post(
      ip_api + '/users/asociarUser',
      {
        id_tecnico: list.idTecnico,
        id_user: list.idUser,
        flujo: '1',
      },
      { responseType: 'json' }
    );
  }

  EditarUser(json: any): Observable<any> {
    return this.http.put(
      ip_api + '/users/byID/' + json.id_user + '/edit',
      {
        name: json.name,
        email: json.email,
        role: [json.role],
        active: json.status,
      },
      { responseType: 'json' }
    );
  }

  cambioPass(list: any): Observable<any> {
    return this.http.post(
      ip_api + '/users/cambiarPassword',
      {
        id_user: list.idUser,
        password: list.password,
      },
      { responseType: 'json' }
    );
  }
}
