import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef
} from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { Router } from '@angular/router';
import { GestinarUsuariosService } from '../../../../services/administrador/usuarios/gestinar-usuarios.service';
import { Myvalidations } from '../../../..//utility/MyValidation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { UserService } from '../../../../services/user/user.service';


//Modulo de Formularios
import {
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';

declare var window: any;


@Component({
  selector: 'app-gestionar-usuarios',
  templateUrl: './gestionar-usuarios.component.html',
  styleUrls: ['./gestionar-usuarios.component.css']
})
export class GestionarUsuariosComponent {
  @ViewChild('pass') valuePass!: ElementRef<HTMLSelectElement>;
  @ViewChild('rPass') valueRpass!: ElementRef<HTMLInputElement>;

  @ViewChild('buscar') valueBuscar!: ElementRef<HTMLSelectElement>;
  @ViewChild('tecnico') valueTecnico!: ElementRef<HTMLInputElement>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    protected tokenStorage: TokenStorageService,
    protected router: Router,
    protected toastr: ToastrService,
    protected fb: FormBuilder,
    protected renderer: Renderer2,
    protected spinner: NgxSpinnerService,
    private _liveAnnouncer: LiveAnnouncer,
    // Servicios Exportados

    protected userService: UserService,
    protected GestinarUsuariosService: GestinarUsuariosService,

)  { }

listUser:any
Pass:any
asociar:any
idUser:any;
titleMessage:any
message:any
listTecnicos:any =[];
listTecnicosOld:any =[];

modals:any = {
  VerDetalle:0,
  ActuTecnico:0,
  ChangePass:0,
  DltTecnico:0
}

status:any = [{
  name:"Activo",
  value:"A"
},
{
  name:"Inactivo",
  value:"I"
}]

dataSource:any;

clickedRow = new Set()
items: number = 0

ngAfterViewInit(): void {

  let screenHeight = window.screen.height;
  if (screenHeight <= 1400 && screenHeight >= 1101){
    this.items = 18
  } else if (screenHeight <= 1100 && screenHeight >= 901){
    this.items = 15
  } else if (screenHeight <= 900){
    this.items = 10
  }


  this.cargarUsuarios();
  // this.dataSource = new MatTableDataSource(this.listUser);
  // this.dataSource.filterPredicate = (data: any, filter: string) => {
  //   return data.active === filter;
  // };

  // this.dataSource.filter = checked ? 'I' : 'A';
  // this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;

  this.modals.VerDetalle = new window.bootstrap.Modal(
    document.getElementById('VerDetalle')
  );

  this.modals.ActuTecnico = new window.bootstrap.Modal(
    document.getElementById('ActuTecnico')
  );

  this.Pass = new window.bootstrap.Modal(
    document.getElementById('Pass')
  );


  this.asociar = new window.bootstrap.Modal(
    document.getElementById('asociar')
  );

  this.ConsultaTecnicos();
  this.ObtenerRoles()

}


listaTemporal: any = [];

onKeyUpEvent(filter: any) {
  if (filter.target.value == '') {
    this.listTecnicos = this.listaTemporal;
  } else {
    this.BuscarTecnico1(this.listaTemporal, filter.target.value);
  }
}




announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


arrayBuscador:any
// onKeypressEvent(event:any):any{
//   // Obtener valor target en el evento keypress
//   // alert(event.target.value)
//   if(event.target.value != ''){
//     // this.arrayBuscador
//     // this.checkUnput.nativeElement.checked = false



//     // let filterStatus:string

//     // if(this.checkUnput.nativeElement.checked){
//     //   filterStatus = 'I'
//     // }

//     // if(!this.checkUnput.nativeElement.checked){
//     //   filterStatus = 'A'
//     // }

//     // this.dataSource = this.arrayBuscador.filter((item: any) => item.name.
//     // toUpperCase().includes(event.target.value.toString().toUpperCase())
//     // && item.active == filterStatus);
//     // console.log(event.target.value.toLowerCase());

//     // this.dataSource.filter = event.target.value.trim().toLowerCase();

//     this.dataSource.filterPredicate = (data: any, filter: string) => {
//       const dataStr = data.name.toLowerCase() + data.email.toLowerCase() + data.descripcion_rol.toLowerCase();
//       return dataStr.indexOf(filter) !== -1;
//     };
//     this.dataSource.filter = event.target.value.trim().toLowerCase();

//   }




//   if(event.target.value == ''){
//     this.cargarUsuarios()
//   }


// }

applyFilter(event: Event) {

  let checked = this.checkUnput.nativeElement.checked;
  const filterValue = (event.target as HTMLInputElement).value;

  if (filterValue != '') {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const dataStr = data.name.toLowerCase() + data.descripcion_rol.toLowerCase();
      return dataStr.indexOf(filter) !== -1 && data.active === (checked ? 'I' : 'A');
    };
    this.dataSource.filter = filterValue.trim().toLowerCase();
  } else {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.active === filter;
    };
    this.dataSource.filter = checked ? 'I' : 'A';
  }
}


BuscarUsuario(param:any):any{
  //
    // this.arrayBuscador

    let checked = this.checkUnput.nativeElement.checked;

    // this.dataSource = this.arrayBuscador.filter((item: any) => item.name.
    // toUpperCase().includes(param.toString().toUpperCase())
    // && item.active == filterStatus);
    // this.dataSource.paginator = this.paginator;
    if (param != '') {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const dataStr = data.name.toLowerCase() + data.descripcion_rol.toLowerCase();
        return dataStr.indexOf(filter) !== -1 && data.active === (checked ? 'I' : 'A');
      };
      this.dataSource.filter = param.trim().toLowerCase();
    } else {
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.active === filter;
      };
      this.dataSource.filter = checked ? 'I' : 'A';
    }
}
idUsuario:any
TecnicoAsignado:any
openAsociar(idUsuario:any, NombreTecnico:any){
  this.valueBuscar.nativeElement.value = '';
  this.valueTecnico.nativeElement.value = '';
  this.idUsuario=idUsuario;
  this.TecnicoAsignado=NombreTecnico;
  this.asociar.show();

}

ConsultaTecnicos(){

  this.GestinarUsuariosService.getListTecnicos().subscribe({
    next: (data) => {

      let list = data;

      list.forEach((element: any) => {
        if(element.id_user==null){
          this.listTecnicos.push(element);
          this.listTecnicosOld.push(element);
          this.listaTemporal.push(element);
        }
      });



    },
    error: (err) => {

      this.toastr.error('Error al consultar Tecnicos',
                        'la consulta de los tecnicos a asociar no fue culminada con exito');
    },
  });

}

BuscarTecnico(nombreTecnico:any){


  if(nombreTecnico ==''){
    this.listTecnicos = this.listTecnicosOld;
  }
  else{
    let listTecnicosNew:any =[];
    let busqueda:any =
    this.listTecnicos.filter((element: any) =>
    element.nombre_tecnico.toUpperCase().includes(nombreTecnico.toString().toUpperCase()));

    busqueda.forEach((element: any) => {
      listTecnicosNew.push(element)
    });
    this.listTecnicos={};
    this.listTecnicos=listTecnicosNew;

  }
}


BuscarTecnico1(obj: any, parm: string): any {
  this.listTecnicos = [];
  // Filtrar el Array y buscar por el Parametro convirtiendo en Mayuscua
  // El Nombre y parametro a Buscar
  let busqueda: any = obj.filter((element: any) =>
    element.nombre_tecnico
      .toUpperCase()
      .includes(parm.toString().toUpperCase())
  );

  // Agregar al Array que se va a mostrar
  busqueda.forEach((element: any) => {
    this.listTecnicos.push(element);
  });
}



// Obtener Roles del Servicio
listRoles:any = []
ObtenerRoles():void{
  this.GestinarUsuariosService.getRoles().subscribe({
    next: (data) => {

      data.forEach((item: any) => {
        if(item.name !== 'ROLE_SUPERADMIN' && item.name !== 'ROLE_SERVICES' && item.name !== 'ROLE_USER'){
        this.listRoles.push(item)
        }
      });

      // this.listRoles = data.filter((item: any) => item.name !== 'ROLE_SUPERADMIN' );
      //
    },
  });
}

DetalleUsuario:any = {
  NombreCompleto:{}
}
VerDetalle(param:any,user: object):void{

  // En Caso de ser uno Muestra el Modal
  if(param == '1'){
    // this.listUser.forEach((Element:any) => {
    //   if(Element.id == id_usuario){
    //     this.DetalleUsuario = Element;
    //     //
    //     // alert(this.DetalleUsuario)

    //   }
    // });
    this.DetalleUsuario = user;
    // this.DetalleUsuario = this.listUser.filter((item: any) => item.id == id_usuario)[0];
    //
    this.modals.VerDetalle.show()
  }

  // En caso de ser cero cierra el modal
  if(param == '0'){
    this.DetalleUsuario.NombreCompleto = {}
    this.modals.VerDetalle.hide()

     //
  }
}

closemoda():void{

}


// Boton check para filtrar a los clientes:

@ViewChild('checkUnput') checkUnput!: ElementRef;
estado:string  = 'Activos'
check:string = 'true'
FiltrarUser():any{

  if(this.checkUnput.nativeElement.checked == true){
    this.estado = 'Inactivos'

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.active === filter;
    };
    this.dataSource.filter = 'I';

  }else{
    this.estado = 'Activos'
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.active === filter;
    };
    this.dataSource.filter = 'A';
  }

}

// Cargar Modal Editar Tecnico
// -----------------------------------------------------
estatusd:any = []
ActuTecnico(param:any,id_usuario?:number):any{

// En Caso de ser uno Muestra el Modal
if(param == '1'){
  // this.estatusd = []
  // this.DetalleUsuario = {}
  // this.UserForm.reset()

  this.listUser.forEach((Element:any) => {

    if(Element.id == id_usuario){
      this.estatusd = this.status
      this.DetalleUsuario = Element;
      this.DetalleUsuario.NombreCompleto = this.DetalleUsuario.name.split(" ")
      //
      // alert(this.DetalleUsuario)
      return;
    }
  });

  // Setear Valores al Formulario
  this.UserForm.get("name")!.setValue(this.DetalleUsuario.NombreCompleto[0]);
  this.UserForm.get("apellido")!.setValue(this.DetalleUsuario.NombreCompleto[1]);
  this.UserForm.get("email")!.setValue(this.DetalleUsuario.email);
  this.UserForm.get("status")!.setValue(this.DetalleUsuario.active);
  this.UserForm.get("user_rol")!.setValue(this.DetalleUsuario.nombre_rol);
  this.modals.ActuTecnico.show()
}

// En caso de ser cero cierra el modal
if(param == '0'){
  this.estatusd = []
  // this.UserForm.reset()
  // this.DetalleUsuario = {}
  this.modals.ActuTecnico.hide()
}
}


UserForm = this.fb.group({
  name: ['',[Validators.required]],
  apellido: ['',[Validators.required]],
  email: ['',[Validators.required,Validators.email,this.emailDomainValidator]],
  status: ['',[Validators.required]],
  user_rol: ['',[Validators.required]]
},{ updateOn: 'change' });


// Obtener El rol en caso de que sea no mostrar el Select
@ViewChild('rol_status') rol_status!: ElementRef;

emailDomainValidator(control: FormControl) {
  const email = control.value;
  if (email && email.indexOf('@') !== -1) {
    const [_, domain] = email.split('@');
    if (domain !== 'airtek.com.ve') {
      return {
        emailDomain: {
          parsedDomain: domain,
        },
      };
    }
  }
  return null;
}



// Procesar Editar Usuario
ProcesarEditarUser(status:any,user_rol:any):any{
  //
  //

  let RolPre:any
  if(user_rol == undefined){
    RolPre = this.UserForm.get("user_rol")?.value
  }

  if(user_rol != undefined){
    RolPre = user_rol.nativeElement.value
  }

  let json:any = {
    name:(this.UserForm.get("name")?.value + ' ' + this.UserForm.get("apellido")?.value),
    email:this.UserForm.get("email")?.value,
    role:RolPre,
    status:status,
    id_user:this.DetalleUsuario.id
  }



  // Quitar el Validador del user rol, por si viene vacio
  // En caso de que sea Vacio Asignar el Rol Anterior


  if(this.UserForm.valid){
    this.GestinarUsuariosService.EditarUser(json).subscribe({
      next: (data) => {
        // alert(JSON.stringify(data))
        if(data){
          if(data.success==false){
            this.toastr.error(data.message);
          }else{
            this.toastr.success("Completa",data.message)
            this.cargarUsuarios()
            this.estatusd = []
            this.modals.ActuTecnico.hide()
          }
        }
        // alert(JSON.stringify(data))
        //
      },error: (err) => {

        this.toastr.error('Error al editar tecnicos');
        this.modals.ActuTecnico.hide()
      },
    });
  }


}


cargarUsuarios():void{

  this.GestinarUsuariosService.getUser().subscribe({
    next: (data) => {

      // Reset Check Button
      this.checkUnput.nativeElement.checked = false
      this.estado = 'Activos'

      this.listUser = data;
      let listerNew = this.listUser.filter(
        (word: any) => word.nombre_rol != "ROLE_SERVICES" && word.nombre_rol != "ROLE_SUPERADMIN"
      );
      this.listUser = listerNew;
      // Asignar el dataSource después de obtener los datos
      this.dataSource = new MatTableDataSource(this.listUser);
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        return data.active === filter;
      };
      this.dataSource.filter = 'A';
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.arrayBuscador = data
      //
    },error: (err) => {

      this.toastr.error('Error al consultar usuarios');
    },
  });


}


openRestablecer(idUser:any){
  this.valuePass.nativeElement.value = '';
  this.valueRpass.nativeElement.value = '';
  this.passForm.markAsUntouched()
  this.idUser = idUser;
  this.Pass.show()
}

saveRestablecer(){
  this.Pass.hide()
  this.valuePass.nativeElement.value = '';
  this.valueRpass.nativeElement.value = '';
  this.passForm.markAsUntouched()
}


tipo:any = "password"
mostrarContrasena(){
 const eye:any = document.getElementById('eye');
 const eyeSlash:any = document.getElementById('eyeSlash');
 if(this.tipo == "password"){
     this.tipo = "text";
     eye.classList.add('d-none');
     eyeSlash.classList.remove('d-none');
 }else{
     this.tipo = "password";
     eye.classList.remove('d-none');
     eyeSlash.classList.add('d-none');
 }
}

tipo1:any = "password"
mostrarContrasena1(){
const eye1:any = document.getElementById('eye1');
const eyeSlash1:any = document.getElementById('eyeSlash1');
if(this.tipo1 == "password"){
   this.tipo1 = "text";
   eye1.classList.add('d-none');
   eyeSlash1.classList.remove('d-none');
}else{
   this.tipo1 = "password";
   eye1.classList.remove('d-none');
   eyeSlash1.classList.add('d-none');
}
}

passForm = this.fb.group({
  password: ['',[Validators.required,Validators.minLength(6)]],
  Rpassword: ['',[Validators.required,Validators.minLength(6)]]
},{
  validator: Myvalidations.ConfirmedValidator('password', 'Rpassword')
});

 fromPass:any
ProcesarPassword(){

  if (this.passForm.valid) {
    this.fromPass = {
      idUser: this.idUser,
      password: this.passForm.get('password')?.value,
    };

    this.GestinarUsuariosService.cambioPass(
      this.fromPass
    ).subscribe({
      next: (data) => {
        //
        const responseProcesar = data;


        this.titleMessage = responseProcesar.descripcion;
        this.message = responseProcesar.detalle;
        this.toastr.success(this.message, this.titleMessage, {
          timeOut: 5000,
        });
        setTimeout(() => {
          this.Pass.hide()
          this.valuePass.nativeElement.value = '';
          this.valueRpass.nativeElement.value = '';
        }, 400);
      },
      error: (err) => {
        //
        this.titleMessage = err.error.descripcion;
        this.message = err.error.detalle;
        this.toastr.error(err.error.detalle, this.titleMessage);
        this.Pass.hide()
        this.valuePass.nativeElement.value = '';
        this.valueRpass.nativeElement.value = '';
      },
    });
    setTimeout(() => {
      this.Pass.hide()
      this.valuePass.nativeElement.value = '';
      this.valueRpass.nativeElement.value = '';

    }, 200);


  }
}


asociarForm = this.fb.group({
  tecnico: ['',[Validators.required]]
},{ updateOn: 'change' });

fromAsociar:any
procesarAsociar(){
  if (this.asociarForm.valid) {

    this.fromAsociar = {
      idUser:  this.idUsuario,
      idTecnico: this.asociarForm.get('tecnico')?.value,
    };

    this.GestinarUsuariosService.asosiar(
      this.fromAsociar
    ).subscribe({
      next: (data) => {
        //
        const responseProcesar = data;
        this.titleMessage = responseProcesar.descripcion;
        this.message = responseProcesar.detalle;

        if(responseProcesar.statusMensajes=='2'){

          this.toastr.error(this.message, this.titleMessage, {
            timeOut: 5000,
          });
          setTimeout(() => {
            this.asociar.hide()

          }, 400);
        }
        else{


          this.toastr.success(this.message, this.titleMessage, {
            timeOut: 5000,
          });
          setTimeout(() => {
            this.asociar.hide()
            this.valueBuscar.nativeElement.value = '';
            this.valueTecnico.nativeElement.value = '';

          }, 400);

        }

      },
      error: (err) => {
        //
        this.titleMessage = err.error.descripcion;
        this.message = err.error.detalle;
        this.toastr.error(err.error.detalle, this.titleMessage);
        this.asociar.hide()
        this.valueBuscar.nativeElement.value = '';
        this.valueTecnico.nativeElement.value = '';

      },
    });
    setTimeout(() => {
      this.asociar.hide()
      this.valueBuscar.nativeElement.value = '';
      this.valueTecnico.nativeElement.value = '';
    }, 200);


  }
}


}











