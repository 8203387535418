
import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { Router } from '@angular/router';
import { GestinarUsuariosService } from '../../../../services/administrador/usuarios/gestinar-usuarios.service';
import { Myvalidations } from '../../../..//utility/MyValidation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../services/user/user.service';

//Modulo de Formularios
import {
  FormBuilder,
  Validators,
} from '@angular/forms';

declare var window: any;

@Component({
  selector: 'app-registrar-usuarios',
  templateUrl: './registrar-usuarios.component.html',
  styleUrls: ['./registrar-usuarios.component.css']
})
export class RegistrarUsuariosComponent {
  @ViewChild('buscar') valueBuscar!: ElementRef<HTMLInputElement>;
  @ViewChild('tecnico') valueTecnico!: ElementRef<HTMLInputElement>;
  constructor(
    protected tokenStorage: TokenStorageService,
    protected router: Router,
    protected toastr: ToastrService,
    protected fb: FormBuilder,
    protected renderer: Renderer2,
    protected spinner: NgxSpinnerService,

    // Servicios Exportados

    protected userService: UserService,
    protected GestinarUsuariosService: GestinarUsuariosService
)  { }

  listRoles:any;
  listTecnicos:any =[];
  listTecnicosOld:any =[];
  disable:boolean=true;
  cumplida:any

  ngAfterViewInit(): void {

    this.GestinarUsuariosService.getRoles().subscribe({
      next: (data) => {
        this.listRoles = data;

      },
    });

    this.ConsultaTecnicos();



    this.cumplida = new window.bootstrap.Modal(
      document.getElementById('cumplida')
    );


  }


  rolUser(rol:any){
    const divA:any = document.getElementById('hr');
    const divB:any = document.getElementById('div1');
    const divC:any = document.getElementById('div2');

    this.valueBuscar.nativeElement.value = '';
    this.valueTecnico.nativeElement.value = '';

    if(rol=="ROLE_USER"){
      this.disable=false;
      divA.classList.remove('d-none');
      divB.classList.remove('d-none');
      divC.classList.remove('d-none');
    }
    else{
      this.disable=true;
      divA.classList.add('d-none');
      divB.classList.add('d-none');
      divC.classList.add('d-none');
    }
    //
  }


  ConsultaTecnicos(){

    this.GestinarUsuariosService.getListTecnicos().subscribe({
      next: (data) => {

        let list = data;

        list.forEach((element: any) => {
          if(element.id_user==null){
            this.listTecnicos.push(element);

            this.listTecnicosOld.push(element);

            this.listaTemporal.push(element);
          }
        });



      },
      error: (err) => {

        this.toastr.error('Error al consultar Tecnicos');
      },
    });

  }

  listaTemporal: any = [];

onKeyUpEvent(filter: any) {
  if (filter.target.value == '') {
    this.listTecnicos = this.listaTemporal;
  } else {
    this.BuscarTecnico1(this.listaTemporal, filter.target.value);
  }
}

  BuscarTecnico(nombreTecnico:any){


    if(nombreTecnico ==''){
      this.listTecnicos = this.listTecnicosOld;
    }
    else{
      let listTecnicosNew:any =[];
      let busqueda:any =
      this.listTecnicos.filter((element: any) =>
      element.nombre_tecnico.toUpperCase().includes(nombreTecnico.toString().toUpperCase()));

      busqueda.forEach((element: any) => {
        listTecnicosNew.push(element)
      });
      this.listTecnicos={};
      this.listTecnicos=listTecnicosNew;

    }
  }

  BuscarTecnico1(obj: any, parm: string): any {
    this.listTecnicos = [];
    // Filtrar el Array y buscar por el Parametro convirtiendo en Mayuscua
    // El Nombre y parametro a Buscar
    let busqueda: any = obj.filter((element: any) =>
      element.nombre_tecnico
        .toUpperCase()
        .includes(parm.toString().toUpperCase())
    );
  
    // Agregar al Array que se va a mostrar
    busqueda.forEach((element: any) => {
      this.listTecnicos.push(element);
    });
  }

  userFrom = this.fb.group(
    {
      // nombre: ['', [Validators.required,Validators.pattern(/^[a-zA-Z]+$/)]],
      nombre: ['', [Validators.required]],
      // apellido: ['', [Validators.required,Validators.pattern(/^[a-zA-Z]+$/)]],
      apellido: ['', [Validators.required]],
      correo: ['', [Validators.required,Validators.email,this.emailDomainValidator]],
      rol: ['', [Validators.required]],
      password: ['', [Validators.required,
                      Validators.minLength(6)] ],
      Rpassword: ['', [Validators.required,Validators.minLength(6)],

                      //  [this.password(userFrom)]
    ],
    },
    {
    // validators: this.password.bind(this),
       validator: Myvalidations.ConfirmedValidator('password', 'Rpassword')
    },
    );





    // getErrorMsg():string {
    //
    //   if (this.userFrom.valid) {
    //     return '';
    //   }
    //   let err = this.userFrom.errors;
    //   if (err && err['notSame']) { //aquí sólo aparecen los errores globales!
    //     return 'Las contraseñas no son iguales'
    //   }
    //   return 'Las contraseñas tienen menos de 3 caracteres';
    // }


  // checkPasswords(group: FormGroup) {
  //   let pass = group.get("password")!.value;
  //   let confirmPass = group.get("Rpassword1")!.value;

  //   return pass === confirmPass ? null : { notSame: true };
  // }

  //var

  formProcesar: any = {};
  nombre:any;
  apellido:any;
  nombreCompleto:any;
  Usuario:any;
  message = '';
  titleMessage = '';
  pass:any;
  Rpass:any;
  CompPass:any =false;
  userName:any

  emailDomainValidator(control: FormControl) {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (domain !== 'airtek.com.ve') {
        return {
          emailDomain: {
            parsedDomain: domain,
          },
        };
      }
    }
    return null;
  }




  saveAddUsuario(tecnico:any):void{


    this. userFrom.markAllAsTouched();


    if (this.userFrom.valid) {
      this.formProcesar = {
        nombre: this.userFrom.get('nombre')?.value,
        apellido: this.userFrom.get('apellido')?.value,
        correo: this.userFrom.get('correo')?.value,
        role: this.userFrom.get('rol')?.value,
        pass: this.userFrom.get('password')?.value,
      };

      this.GestinarUsuariosService.InsertUser(
        this.formProcesar
      ).subscribe({
        next: (data) => {
          //

          if(data.success){
            const responseProcesar = data;
            this.userName = responseProcesar.username;


            if(tecnico!=''){
              this.asociarUser(tecnico,responseProcesar.id_user);
            }

            this.message = responseProcesar.message;
            this.toastr.success(this.message, '', {
              timeOut: 5000,
            });
            setTimeout(() => {
              //
              this.modalResultado(this.userName);

            }, 200);
          } else {
            this.titleMessage = 'Usuario no registrado'
            this.message = data.message;
            this.toastr.error(this.message, this.titleMessage);
          }
        },
        error: (err) => {
          //
          this.titleMessage = err.error.descripcion;
          this.message = err.error.detalle;
          this.toastr.error(err.error.detalle, this.titleMessage);
        },
      });
    }
  }


  modalResultado(userName:any){
    this.userName=userName
    this.cumplida.show();
  }


  completado(){
    this.cumplida.hide();
    window.location.reload();
  }

  asociarUser(idTecnico:any, idUser:any){

    let listAsociar={
      idTecnico:idTecnico,
      idUser:idUser
    }

    this.GestinarUsuariosService.asosiar(
      listAsociar
    ).subscribe({
      next: (data) => {
        const responseProcesar = data;
        this.titleMessage = responseProcesar.descripcion;
        this.message = responseProcesar.detalle;
        this.toastr.success('Proceso Completado', 'Asociar Usuario Tecnico', {
          timeOut: 5000,
        });

      },
      error: (err) => {
        //
        this.titleMessage = err.error.descripcion;
        this.message = err.error.detalle;
        this.toastr.error(err.error.detalle, this.titleMessage);
      },
    });
  }


  tipo:any = "password"
   mostrarContrasena(){
    const eye:any = document.getElementById('eye');
    const eyeSlash:any = document.getElementById('eyeSlash');
    if(this.tipo == "password"){
        this.tipo = "text";
        eye.classList.add('d-none');
        eyeSlash.classList.remove('d-none');
    }else{
        this.tipo = "password";
        eye.classList.remove('d-none');
        eyeSlash.classList.add('d-none');
    }
}

tipo1:any = "password"
mostrarContrasena1(){
  const eye1:any = document.getElementById('eye1');
  const eyeSlash1:any = document.getElementById('eyeSlash1');
  if(this.tipo1 == "password"){
      this.tipo1 = "text";
      eye1.classList.add('d-none');
      eyeSlash1.classList.remove('d-none');
  }else{
      this.tipo1 = "password";
      eye1.classList.remove('d-none');
      eyeSlash1.classList.add('d-none');
  }
}
}
