
//Diccionario Para Mapear la ruta de imagenes e Invocarlas en todas las rutas
//

export const ruta_img = {
    
    //Login
    sigo: '/assets/svg/SIGO_LOGO.png',
    menu_icon : '/assets/svg/menu-burger.png',
    logo:  '/assets/svg/logo.svg',
    logoBlack:'/assets/img/logoblack.png',
    ImagePath_2 : '/assets/svg/frame.png',
    logo_flecha: '/assets/svg/goflecha.png',
    
    logo_new: '/assets/svg/Component3.svg',
    
    logo_new2: '/assets/svg/Component4.svg',

    logo_g : '/assets/svg/Component5.svg',
    logo_a : '/assets/svg/Component6.svg',
    //Home Layout
    Logout: '/assets/svg/Logout.png',


    //Nav
    noimg:'/assets/img/noimg.png',

    //home component

    wrench:'/assets/img/wrench.png',

    //location
    lAirtek: '/assets/img/location_Airtek.png',
    lAveria: '/assets/img/location_Averia.png',
    lAsignadas: '/assets/img/location_Asignadas.png',
    lEnProceso: '/assets/img/location_En_proceso.png',
    lPausada: '/assets/img/Location_Pausa.png',
    lCulminada: '/assets/img/location_Completadas.png',
    lCancelada: '/assets/img/location_Canceladas.png',


    //service

    ont : '/assets/svg/ontv2.svg',
    port : '/assets/svg/portv2.svg',
    router : '/assets/svg/routerv2.svg',
}