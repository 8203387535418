<ngx-spinner type="ball-atom" bdColor="rgba(0, 0, 0, 0.9)"
  ><p class="loadText">Cargando...</p></ngx-spinner
>

<div class="mt-5 container-fluid">
  <div class="d-flex align-items-center tituloModulo mb-2">
    <i class="fa-solid fa-user-plus mb-2 me-2 iconTitulo"></i>
    <h3>Registrar Usuarios</h3>
  </div>
  <form
    [formGroup]="userFrom"
    (ngSubmit)="saveAddUsuario(tecnico.value)"
    id="fromUser"
    novalidate
  >
    <div class="d-flex row">
      <div class="col-md-3 mb-3 formEmi">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Nombre</strong></label
        >
        <input
          type="text"
          name="nombre"
          class="form-control EmiInput"
          formControlName="nombre"
          autocomplete="off"
        />
        <div
          class="container-error-message"
          *ngIf="userFrom.get('nombre')?.touched
          && userFrom.get('nombre')?.errors?.['required']
          "
          id="error_input"
        >
          El campo es requerido
        </div>
        <!-- <p
        id="error_p"
        *ngIf="userFrom.get('nombre')?.errors?.['pattern']
             || userFrom.get('nombre')?.hasError('message')"
      >
      Espacio no valido
      </p> -->
      </div>
      <div class="col-md-3 mb-3 formEmi">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Apellido</strong></label
        >
        <input
          type="text"
          name="apellido"
          class="form-control EmiInput"
          formControlName="apellido"
          autocomplete="off"
        />
        <div
          class="container-error-message"
          *ngIf="userFrom.get('apellido')?.touched
            && userFrom.get('apellido')?.errors?.['required']
            "
          id="error_input"
        >
          El campo es requerido
        </div>
        <!-- <p
        id="error_p"
        *ngIf="userFrom.get('apellido')?.errors?.['pattern']
             || userFrom.get('apellido')?.hasError('message')"
      >
        Espacio no valido
      </p> -->
      </div>
      <div class="col-md-3 mb-3 formEmi">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Correo Airtek</strong></label
        >
        <input
          type="email"
          name="correo"
          class="form-control EmiInput"
          formControlName="correo"
          autocomplete="off"
        />
        <div
          class="container-error-message"
          *ngIf="userFrom.get('correo')?.touched
            && userFrom.get('correo')?.errors?.['required']
            "
          id="error_input"
        >
          El campo es requerido
        </div>
        <div
        class="container-error-message"
        *ngIf="userFrom.get('correo')?.touched
          && userFrom.get('correo')?.errors?.['email']
          "
        id="error_input"
      >
        Correo no valido
      </div>
      <div
      class="container-error-message"
      *ngIf="userFrom.get('correo')?.touched
        && userFrom.get('correo')?.errors?.['emailDomain']
        "
      id="error_input"
    >
      El correo debe ser de airtek.com.ve
    </div>
      </div>
      <div class="col-md-3 mb-3 formEmi">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Rol</strong></label
        >

        <select
        name="rol"
        formControlName="rol"
        class="form-control EmiInput"
        autocomplete="off"
        #rol
        (click)="rolUser(rol.value)"
      >
        <option value="">Seleccione</option>
        <ng-container *ngFor="let listRoles of listRoles">
          <option
            *ngIf="listRoles.name != 'ROLE_SUPERADMIN' && listRoles.name != 'ROLE_SERVICES' && listRoles.name != 'ROLE_CLIENTE'"
            [value]="listRoles.name"
          >
            {{ listRoles.description }}
          </option>
        </ng-container>
      </select>
        <div
          class="container-error-message"
          *ngIf="userFrom.get('rol')?.touched
            && userFrom.get('rol')?.errors?.['required']
            "
          id="error_input"
        >
          El campo es requerido
        </div>
      </div>
      <div class="col-md-3 mb-3 formEmi">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Contraseña</strong></label
        >
        <div class="case">
          <input
            [type]="tipo"
            name="password"
            class="form-control EmiInput"
            formControlName="password"
            autocomplete="off"
          />

          <div class="caseEye">
            <fa-icon
              [icon]="['fas', 'eye']"
              class="iconEye"
              (click)="mostrarContrasena()"
              id="eye"
            ></fa-icon>
            <fa-icon
              [icon]="['fas', 'eye-slash']"
              class="iconEye d-none"
              (click)="mostrarContrasena()"
              id="eyeSlash"
            ></fa-icon>
          </div>
        </div>
        <div
          class="container-error-message"
          *ngIf="userFrom.get('password')?.touched
        && userFrom.get('password')?.errors?.['required']
        "
          id="error_input"
        >
          El campo es requerido
        </div>
        <div
        class="container-error-message"
        *ngIf="userFrom.get('password')?.touched
      && userFrom.get('password')?.errors?.['minlength']
      "
        id="error_input"
      >
      Ingrese una contraseña mayor a seis (06) digitos
      </div>

      </div>
      <div class="col-md-3 mb-3 formEmi">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Repetir Contraseña</strong></label
        >
        <div class="case">
          <input
            [type]="tipo1"
            name="Rpassword"
            class="form-control EmiInput"
            formControlName="Rpassword"
            autocomplete="off"
          />
          <div class="caseEye">
            <fa-icon
              [icon]="['fas', 'eye']"
              class="iconEye"
              (click)="mostrarContrasena1()"
              id="eye1"
            ></fa-icon>
            <fa-icon
              [icon]="['fas', 'eye-slash']"
              class="iconEye d-none"
              (click)="mostrarContrasena1()"
              id="eyeSlash1"
            ></fa-icon>
          </div>
        </div>
        <div
          class="container-error-message"
          *ngIf="userFrom.get('Rpassword')?.touched
          && userFrom.get('Rpassword')?.errors?.['required']
          "
          id="error_input"
        >
          El campo es requerido
        </div>
        <div
        class="container-error-message"
        *ngIf="userFrom.get('Rpassword')?.touched
      && userFrom.get('Rpassword')?.errors?.['minlength']
      "
        id="error_input"
      >
      Ingrese una contraseña mayor a seis (06) digitos
      </div>
      <div class="container-error-message" id="error_input" *ngIf="userFrom.get('Rpassword')?.errors?.['confirmedValidator'] ||
      userFrom.get('password')?.errors?.['confirmedValidator']" >
        Las contraseñas no coinciden
      </div>
      </div>
      <hr class="my-3 d-none" id="hr"/>
     <div class="col-md-4 mb-3 formEmi d-none" id="div1">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Buscar Técnico</strong></label
        >
        <div class="d-flex flex-row">
        <input
          type="text"
          name="buscar"
          class="form-control EmiInput"
          placeholder="Nombre Técnico"
          (keyup)="onKeyUpEvent($event)"
          #buscar
        />
        <!-- <button
        type="button"
        class="btn-buscar d-flex justify-content-center ms-3"
        id="buscarCliente"
        (click)="BuscarTecnico(buscar.value)"
      >
        <i class="fa-solid fa-magnifying-glass"></i>
      </button> -->
    </div>
      </div>
      <div class="col-md-3 mb-3 formEmi d-none" id="div2">
        <label for="exampleInputEmail1" class="form-label"
          ><strong>Tecnico a Asociar</strong></label
        >
        <select
        name="rol"
        class="form-control EmiInput"
        autocomplete="off"
        [disabled]="disable"
        #tecnico
      >
        <option value="">Seleccione</option>
        <ng-container *ngFor="let listTecnicos of listTecnicos">
          <option
            [value]="listTecnicos.id_tecnico"
          >
            {{ listTecnicos.nombre_tecnico }}
          </option>
        </ng-container>
      </select>
      </div>
    </div>
    <div class="d-flex row justify-content-end mt-3 gap-3 formDiv2">
      <input type="reset" class="btn btn-Cancelar" value="Cancelar" />
      <input type="submit" class="btn btn-Procesar" value="Procesar" />
    </div>
  </form>
</div>






<div
  class="modal fade"
  id="cumplida"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Usuario Generado</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="completado()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="d-flex row justify-content-center gap-3">

          <h5>Usuario: {{userName}}</h5>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-Procesar me-2" (click)="completado()">Aceptar</button>
      </div>
    </div>
  </div>
</div>
