import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { AuthService } from '../services/login/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { ruta_img } from '../utility/img_rut';
import { Myvalidations } from '../utility/MyValidation';
import { LoadscService } from '../utility/loadsc.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements AfterViewInit {
  //Variables
  ImagePath = ruta_img;
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  errorTitle = '';
  roles: string[] = [];
  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
  @ViewChild('checkedInput') checkedInput!: ElementRef<HTMLInputElement>;
  loading: boolean = false;
  rememberMe: any;
  username: any;
  //Constructor
  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {}

  //Inicio del Componente
  ngAfterViewInit(): void {
    this.spinner.show();

    this.isLoggedIn = !!this.tokenStorage.getToken();
    //

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.router.navigate(['home']);
    }

    //Carga de Imagenes
    this.ImagePath;
    this.rememberMe = localStorage.getItem('rememberMe');

    if (this.rememberMe == 'Yes') {
      // this.renderer.setProperty(
      //   this.userInput.nativeElement,
      //   'value',
      //   localStorage.getItem('username')
      // );
      this.LoginForm.controls['username'].setValue(
        localStorage.getItem('username')
      );
      this.renderer.setProperty(
        this.checkedInput.nativeElement,
        'checked',
        'checked'
      );
    }

    setTimeout(() => {
      /** spinner ends after 1 seconds */
      this.spinner.hide();
    }, 500);
  }

  //Inicio de Sesion

  // Validar el Formulario
  // ,Myvalidations.Login,Validators.pattern(/^[a-zA-Z]+[.]{1}+[a-zA-Z]/)
  // Verificar expresiones regulares
  LoginForm = this.fb.group(
    {
      username: [
        '',
        [
          Validators.required,
          Myvalidations.Login,
          // Validators.pattern(/^[a-zA-Z]+[.]{1}[a-zA-Z]+\d*$/),
        ],
      ],
      password: [
        '',
        {
          validators: [Validators.required, Validators.minLength(6)],
          updateOn: 'change',
        },
      ],

      RememberMe: [],
    },
    { updateOn: 'blur' }
  );

  //Iniciar Sesion
  onSubmit(): void {
    this.isLoginFailed = false;
    this.rememberMe = false;
    // Si el Formulario es valido inicia sesion
    if (this.LoginForm.valid) {
      //Json a Enviar para el Login
      this.form = {
        username: this.LoginForm.get('username')?.value,
        password: this.LoginForm.get('password')?.value,
        deviceInfo: {
          deviceId: '12345-67890',
          deviceType: this.getBrowserName(),
        },
        id_interface: 1,
      };
      this.rememberMe = this.checkedInput.nativeElement.checked;
      if (this.rememberMe == true) {
        this.username = this.LoginForm.get('username')?.value;
        localStorage.setItem('rememberMe', 'Yes');
        localStorage.setItem('username', this.username);
      } else {
        localStorage.setItem('rememberMe', 'No');
        localStorage.setItem('username', '');
      }

      this.authService.login(this.form).subscribe({
        next: (data) => {
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.Session(JSON.stringify(data));
          this.tokenStorage.ExpiryTimout(true);
          this.toastr.success('Bienvenido a SIGO', 'Validación exitosa');
          setTimeout(() => {
            window.location.href = 'home';
          }, 500);
          //Ir a pagina
          // this.loading = false
          // this.router.navigate(['home'])
        },
        error: (err) => {
          if (err.status == 400) {
            this.errorTitle = err.error.descripcion;
            this.errorMessage = err.error.detalle;
            this.isLoginFailed = true;
            // this.loading = false
            //Notificaciones del Login
            this.toastr.error(err.error.detalle, this.errorTitle);
          }else{
            this.toastr.error('No hemos podido conectar, intente más tarde o comuniquse con Sistemas', 'Error de conexión');
          }
        },
      });
    }
  }

  //Obtener navegador
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'BROWSE-EDFE';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'BROWSE-OPERA';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'BROWSE-CHROME';
      case agent.indexOf('trident') > -1:
        return 'BROWSE-TRINDENT';
      case agent.indexOf('firefox') > -1:
        return 'BROWSE-FIREFOX';
      case agent.indexOf('safari') > -1:
        return 'BROWSE-SAFARI';
      default:
        return 'OTHER';
    }
  }

  tipo: any = 'password';
  mostrarContrasena() {
    const eye: any = document.getElementById('eye');
    const eyeSlash: any = document.getElementById('eyeSlash');

    if (this.tipo == 'password') {
      this.tipo = 'text';
      eye.classList.add('d-none');
      eyeSlash.classList.remove('d-none');
    } else {
      this.tipo = 'password';
      eye.classList.remove('d-none');
      eyeSlash.classList.add('d-none');
    }
  }

  MostrarPass(): void {}
}
