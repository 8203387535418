<!DOCTYPE html>
<html lang="en">
  <head> </head>
  <body class="sb-nav-fixed">
    <!--  -->
    <app-navbar></app-navbar>
    <div id="layoutSidenav">
      <!--  -->
      <app-menu-sidenav></app-menu-sidenav>
      <!--  -->
      <div id="layoutSidenav_content">
        <main>
          <div class="container-fluid px-4">
            <router-outlet></router-outlet>
          </div>
        </main>
      </div>
    </div>
  </body>
</html>
