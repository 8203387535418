import { Component } from '@angular/core';
import { TokenStorageService } from '../services/token-storage.service';
import { UserService } from '../services/user/user.service';
import { ruta_img } from './../utility/img_rut';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  constructor(
    private tokenStorage: TokenStorageService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {}

  ImagePath = ruta_img;
  content: any = {};
  currentUser: any;
  shorcuts: any[] = [];
  menu: any = {};

  today: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe: any;
  me: any = this.tokenStorage.GetMe();

  // variable para Precargar los datos para la carga de la pagina
  // En caso de ser True envia al front la carga de los datos
  load: boolean = false;

  ngOnInit(): void {
    this.spinner.show();

    if (!this.me) {
      this.userService.getPublicContent().subscribe({
        next: (data) => {
          this.content = data;
          this.tokenStorage.saveUser(data.username);
          //Saludo automatico segun la hora usando PIPE. By Ron. 30/12/2022
          // this.todayWithPipe = this.pipe.transform(Date.now(), 'H:mm');
          // if (this.todayWithPipe >= '04:00' && this.todayWithPipe < '12:00') {
          //   this.content.saludo = '¡Buenos dias';
          // } else if (
          //   this.todayWithPipe >= '12:00' &&
          //   this.todayWithPipe <= '19:00'
          // ) {
          //   this.content.saludo = '¡Buenas tardes';
          // } else {
          //   this.content.saludo = '¡Buenas noches';
          // }

          //Shorcut
          for (var index in this.content.menu) {
            //

            // En caso de que sea 1 El shortcut esta Activo y se Muestra

            if (
              this.content.menu[index].shorcut == '1' &&
              this.content.menu[index].status != 0 &&
              this.content.menu[index].critico != 'NA'
            ) {
              this.shorcuts.push(this.content.menu[index]);
            }
          }
        },
        error: (err) => {
          this.content = JSON.parse(err.error).message;
        },
      });
    }else{
      this.content = this.me;
      this.tokenStorage.saveUser(this.content.username);
      for (var index in this.content.menu) {
        if (
          this.content.menu[index].shorcut == '1' &&
          this.content.menu[index].status != 0 &&
          this.content.menu[index].critico != 'NA'
        ) {
          this.shorcuts.push(this.content.menu[index]);
        }
      }
    }

    this.currentUser = this.tokenStorage.getUser();

    //Carga Imagenes
    this.ImagePath;

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 500);
  }
}
