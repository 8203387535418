import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api } from '../../API';

//Ruta para el API
const ip_api = Api.ruta;
const API_URL = ip_api + '/averias/';

// Importaciones para el HEADER de las peticiones POST
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AveriaServiceService {
  constructor(private http: HttpClient) {}

  // Obtener Status Averias
  GetStatusAverias(): Observable<any> {
    return this.http.get(API_URL + 'allStatusOperaciones', {
      responseType: 'json',
    });
  }

  //Obtener Status por ID de Operacion
  OperacionesByIdTipoOperacion(id_tipo_operacion: string): Observable<any> {
    return this.http.post(
      API_URL + 'statusOperacionesByIdTipoOperacion',
      {
        id_tipo_operacion,
      },
      httpOptions
    );
  }

  //Obtener Status por ID de Operacion para coordenadas
  OperacionesStatusCoordenadasById(id_tipo_operacion: string): Observable<any> {
    return this.http.post(
      API_URL + 'operacionesStatusCoordenadasByIdTipoOperacion',
      {
        id_tipo_operacion,
      },
      httpOptions
    );
  }
}
