import { Component } from '@angular/core';
import { ruta_img } from '../../utility/img_rut';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent {
  constructor() {}

  ImagePath = ruta_img;

  ngOnInit(): void {
    // this.ConfirmBoxEvokeService.info()
    //Carga Imagenes
    this.ImagePath;
  }
}
