import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api } from "../../API";


//Ruta para el API
const ip_api = Api.ruta
const API_URL = ip_api+'/averias/';
const API_URL_TEC = ip_api+'/airtek/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GestionCuadrillaService {

  constructor(private http: HttpClient) { }

  getListTecnicos(): Observable<any> {
    return this.http.get(ip_api + '/airtek/tecnicos/listarTecnicos', {
  });
  } 

  getListCuadrillas(): Observable<any> {
    return this.http.get(API_URL + 'tecnicos/listarTecnicos', {
  });
  } 

  getConsultaCuadrillas(idNodo: any): Observable<any> {
    return this.http.post(
      API_URL + 'relCuadrillasNodos/relNodoCuadrillaByIdCodigoNodo',
      {
        codigo_nodo: idNodo,
      },
      { responseType: 'json' }
    ); }
    

    getAllcuadrillas(): Observable<any> {
      return this.http.get(API_URL + 'cuadrillas/listarCuadrillasTecnicosAsociadosMdt', {
    }); }




      // Borrar Cuadrilla
      DelCuadrilla(del_cuadrillaRel: any): Observable<any> {
        return this.http.post(
          API_URL + 'cuadrillas/eliminarCuadrillas',
          {
            id_cuadrilla : del_cuadrillaRel.id_cuadrilla
          },
          { responseType: 'json' }
        ); }


      // Crear Cuadrilla
      CrearCuadrilla(crear_cuadrillaRel: any): Observable<any> {
        return this.http.post(
          API_URL + 'cuadrillas/crearCuadrillas',
          {
            descripcion : crear_cuadrillaRel.n_cuadrilla,
            usuario :crear_cuadrillaRel.user
        
          },
          { responseType: 'json' }
        ); }

              // Crear Relacion de la cuadrilla
              CrearRelacionCuadrilla(CrearRelCuadrilla: any): Observable<any> {
                return this.http.post(
                  API_URL + 'relCuadrillasNodos/insertarRelNodoCuadrilla',
                  {
                    id_cuadrilla : CrearRelCuadrilla.id_cuadrilla,
                    insertRelCuadrillasNodosHijosRequest : CrearRelCuadrilla.rel,
                  },
                  { responseType: 'json' }
                ); }


      //Editar Cuadrilla
      editarCuadrillas(edit_cuadrillaRel: any): Observable<any> {
        return this.http.post(
          API_URL + 'cuadrillas/editaCuadrillasRelCuadrillaNodo',
          {
            id_cuadrilla : edit_cuadrillaRel.id_cuadrilla,
            descripcion : edit_cuadrillaRel.descripcion,
            rel_eliminar:  edit_cuadrillaRel.rel_eliminar,
            rel_insertar: edit_cuadrillaRel.rel_insertar
          },
          { responseType: 'json' }
        ); }
      




          // Obtener Nodos
    getListnodos(): Observable<any> {
    return this.http.get(ip_api + '/tecnologia/nodos/listarNodos', {
      responseType: 'json',
    });
  }

  getListOlt(nodo: any): Observable<any> {
    return this.http.post(ip_api + '/tecnologia/olt/listarOltByCodigoNodo', {
      codigo_nodo : nodo
    });
  }

  getListMDT(nodo: any,olt: any): Observable<any> {
    return this.http.post(ip_api + '/tecnologia/mdt/listarMdtByCodigoNodoCodigoOlt', {
      codigo_nodo : nodo,
      codigo_olt : olt
    });
  }


  getListMDTNew(nodo: any): Observable<any> {
    return this.http.post(ip_api + '/tecnologia/mdt/listarMdtByCodigoNodo', {
      codigo_nodo : nodo
    });
  }
    
  getListTipoTecnicos(): Observable<any> {
    return this.http.get(ip_api + '/airtek/tipoTecnicos/listarTipoTecnicos', {
  });
  } 

  getListSucursal(): Observable<any> {
    return this.http.get(ip_api + '/airtek/sucursales/listarSucursales', {
  });
  } 



  postAddTecnico(listProcesar: any): Observable<any> {
    return this.http.post(ip_api + '/airtek/tecnicos/crearTecnicos', {
      codigo_sucursal : listProcesar.codSucursal,
      nombre_tecnico : listProcesar.nombre,
      tipo_tecnico : listProcesar.tipoTecnico,
      id_aliado : listProcesar.codigoProveedor.toString(),
      codigo_instalador : listProcesar.codInstalador,
      codigo_supervisor : listProcesar.codigoSupervisor,
      telefono: listProcesar.telefono,
      mail: listProcesar.correo,
      usuario: listProcesar.usuario,
    });
  }

  postDeleteTecnico(idTecnico: any): Observable<any> {
    return this.http.post(ip_api + '/airtek/tecnicos/eliminarTecnicos', {
      id_tecnico : idTecnico,
    });
  }

  postChangeTecnico(listProcesar: any): Observable<any> {
    return this.http.post(ip_api + '/airtek/tecnicos/actualizarTecnico', {
      id_tecnico : listProcesar.idTecnico,
      codigo_sucursal : listProcesar.codSucursal,
      nombre_tecnico : listProcesar.nombre,
      tipo_tecnico : listProcesar.tipoTecnico,
      telefono : listProcesar.telefono,
      mail : listProcesar.correo,
      codigo_instalador : listProcesar.codInstalador
    });
  }
 
  // -----------------------------------------------
  // Ver detalle de la cuadrilla y ID

  DetalleCuadrillabyID(id_cuadrilla: any): Observable<any> {
    return this.http.post(API_URL + 'relCuadrillasNodos/relNodoCuadrillaByIdCuadrilla', {
      id_cuadrilla : id_cuadrilla,
    });
  }



  // --------------------------------------------------------------------------------
  // Crear Relaciones de Tecnicos en cuadrilla

    // Crear relacion de Cuadrilla
    CrearRelaCuadrillaTecnico(crt_cuadrillaRel: any): Observable<any> {
      return this.http.post(
        API_URL + 'relcuadrillatecnicos/creaRelCuadrillaTec',
        {
          id_cuadrilla : crt_cuadrillaRel.id_cuadrilla,
          id_tecnico : crt_cuadrillaRel.id_tecnico,
          id_tipo_tecnico : crt_cuadrillaRel.id_tipo_tecnico,
        },
        { responseType: 'json' }
      ); }

      getListTecnicosSinRela(): Observable<any> {
        return this.http.get(API_URL_TEC + 'tecnicos/listarTecnicosSinRelacion', {
      });
      } 
    
      DelRelaCuadrilla(del_cuadrillaRel: any): Observable<any> {
        return this.http.post(
          API_URL + 'relcuadrillatecnicos/eliminarRelCuadrillasTecnicos',
          {
            id_cuadrilla : del_cuadrillaRel.id_cuadrilla,
            id_tecnico : del_cuadrillaRel.id_tecnico
          },
          { responseType: 'json' }
        ); }
      
        UpdateCuadrilla(UpdateCuadrilla: any): Observable<any> {
          return this.http.post(
            API_URL + 'relcuadrillatecnicos/modificarRelCuadrillasTecnicos',
            {
              id_cuadrilla_origen : UpdateCuadrilla.id_cuadrilla_origen,
              id_cuadrilla_destino : UpdateCuadrilla.id_cuadrilla_destino,
              id_tecnico : UpdateCuadrilla.id_tecnico,
              id_tipo_tecnico : UpdateCuadrilla.id_tipo_tecnico
            },
            { responseType: 'json' }
          ); }

          getListaAliados(): Observable<any> {
            return this.http.get(ip_api + '/airtek/listarAliados', {
          });
          } 

}
