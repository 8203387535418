import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Api } from '../../API';
import { OrderHistory } from '../../../modules/operaciones/interfaces/orders-history.interface';
import { ToastrService } from 'ngx-toastr';

interface Orden {
  idTipoOrden: string;
  idCliente: string;
  idServicio: string;
  idmotivo: string;
  idUrgencia:string;
  fechaVisita: string;
  comentarios: string;
  usuario: string;
}

//Ruta para el API
const ip_api = Api.ruta;
const API_URL = ip_api + '/averias/';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class EmisionService {
  constructor(
    private http: HttpClient,
    private toast: ToastrService
  ) {}
  //Obtener Tipos Documentos
  getTiposDocumentos(): Observable<any> {
    return this.http.get(
      API_URL + 'tiposIdentificacion/allTiposIdentificacion',
      { responseType: 'json' }
    );
  }

  // Obtener Motivos Averias
  getMotivos(idTipoMotivo: any): Observable<any> {
    return this.http.post(API_URL + 'motivosAveria/ByIdTipoMotivo', {
      id_tipo_motivo: idTipoMotivo,
    });
  }

  getCliente(documento: { tipo: any; documento: any }): Observable<any> {
    return this.http.post(
      ip_api + '/clientes/ByIdentificacion',
      {
        identificacion: documento.documento,
        tipo_identificacion: documento.tipo,
      },
      httpOptions
    );
  }

  getClientebyCode(cliente: { sucursal: any; cliente: any }): Observable<any> {
    return this.http.post(
      ip_api + '/clientes/ByCodeCliente',
      {
        codigo_sucursal: parseInt(cliente.sucursal),
        codigo_cliente: parseInt(cliente.cliente),
      },
      httpOptions
    );
  }



  getClientebyPon(obj:any): Observable<any> {
    return this.http.post(
      ip_api + '/clientes/ByPon',
      {
        user_servicio: obj.pon
      },
      httpOptions
    );
  }

  emitirOrden(orden: {
    idTipoOrden: any;
    idCliente: any;
    idServicio: any;
    idmotivo: any;
    idUrgencia:any;
    fechaVisita: any;
    comentarios: any;
    usuario: any;
    interface: any;
    actualizar: {
      direccionServicio: any,
      telefonoContacto: any
    };
  }): Observable<any> {
    return this.http.post(
      API_URL + 'emitiraveria',
      {
        id_tipo_operacion: orden.idTipoOrden,
        codigo_motivo_previo: orden.idmotivo,
        id_urgencia: orden.idUrgencia,
        fecha_visita: orden.fechaVisita,
        usuario: orden.usuario,
        id_cliente: orden.idCliente,
        id_servicio_cliente: orden.idServicio,
        comentarios: orden.comentarios,
        id_interface: orden.interface,
        referencia: 0,
        fecha_ticket: null,
        actualizar: {
          direccionServicio: orden.actualizar.direccionServicio,
          telefonoContacto: orden.actualizar.telefonoContacto
        }
      },
      httpOptions
    );
  }

  getSucursales(): Observable<any> {
    return this.http.get(ip_api + '/airtek/sucursales/listarSucursales', {});
  }

  getHistoryOrders(idClient: number, idService: number): Observable<OrderHistory[]>{

    const url = `${ API_URL }listarAveriasLastOrder`;

    const params = new HttpParams()
      .set('idCliente', idClient)
      .set('idServicioCliente', idService);

    return this.http.get<OrderHistory[]>(url, { params })
      .pipe(
        catchError( () => throwError( () => this.toast.error('No se ha podido realizar la búsqueda')))
      );
  }
}
