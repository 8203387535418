import { Component } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { ruta_img } from './../../utility/img_rut';
import { UserService } from '../../services/user/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.css'],
})
export class MenuSidenavComponent {
  constructor(
    private UserService: UserService,
    private tokenStorage: TokenStorageService,
    private userService: UserService,
    private router: Router
  ) {}
  content: any = {};
  logout: any;
  ImagePath = ruta_img;
  Menu_link: any[] = [];
  Rol: string = '';

  //JSON de prueba
  json_test: any = {
    deviceInfo: {
      deviceId: '12345-67890',
      deviceType: 'BROWSER-CHROME',
    },
    token: this.tokenStorage.getToken(),
  };
  imgUser:any;
  me: any = this.tokenStorage.GetMe()
  //Inicio del componente
  ngOnInit(): void {
    this.ImagePath;

    // Peticion GET para obtener (/Me)
    if (!this.me) {
      this.userService.getPublicContent().subscribe({
        next: (data) => {
          this.content = data;
          if(this.content.imagenResolucion !=null){
            this.content.imagenResolucion.forEach((element:any) => {

              if(element.img_resolucion=='256'){
                this.imgUser = element.base64;
              }

          });
          }else{
            this.imgUser=null;
          }
          //Armar el Arreglo del menu para Cargar
          // --------------------------------------------
          for (var index in this.content.menu) {
            this.Menu_link.push(this.content.menu[index]);
          }

          // Ordenar el Array del Menu por el codigo de Modulo
          // ---------------------------------------------------------
          //   this.Menu_link.sort(function (a, b) {
          //     // A va primero que B
          //     if (a.codeModule < b.codeModule || a.codeOption < b.codeOption || a.codeDivision < b.codeDivision)
          //         return -1;
          //     // B va primero que A
          //     else if (a.codeModule > b.codeModule)
          //         return 1;
          //     // A y B son iguales
          //     else
          //         return 0;
          // });
          this.Rol = this.content.descripcion_role;
        },
        error: (err) => {
          this.content = JSON.parse(err.error).message;
        },
      });
    } else {
      this.content = this.me;
      if(this.me.imagenResolucion !=null){
        this.me.imagenResolucion.forEach((element:any) => {

          if(element.img_resolucion=='256'){
            this.imgUser = element.base64;
          }

      });
      }else{
        this.imgUser=null;
      }
      for (var index in this.me.menu) {
        this.Menu_link.push(this.me.menu[index]);
      }
      this.Rol = this.me.descripcion_role;
    }
  }

  //Funcio Logout
  logout_1(): void {
    this.UserService.Logout(this.json_test).subscribe({
      next: (data) => {
        this.logout = data;

      },
      error: (err) => {
        // En caso de error mostrar el error
        this.logout = JSON.parse(err.error).message;

      },
    });
    this.tokenStorage.signOut();
    this.router.navigate(['']);

    //window.location.href = ('')
  }
}
