<ngx-spinner type="ball-atom" bdColor="rgba(0, 0, 0, 1)"
  ><p class="loadText">Cargando...</p></ngx-spinner
>
<div class="container-fluid marcaAgua background">
  <div class="row pt-5 boxWelcome">
    <!-- <div class="col-12 text-primary">
      <h1>{{ content.saludo }} {{ content.name }}! </h1>
    </div> -->
    <!-- <div class="col-12 text-primary text-center">
      <h2> !</h2>
    </div> -->
    <!-- <div class="col-12 text-primary">
      <h3>Bienvenido al Sistema Integrado de Gestión Operativa</h3>
    </div> -->
  </div>

  <div class="d-flex p-2 w-100 gap-4 colres flex-wrap">
    <!-- Bucle para cargar los Shorcut del Home -->
    <!-- <div class="text-primary mb-5 srCard " *ngFor="let shorcut of shorcuts">
      <div class="card">
        <a routerLink="{{ shorcut.route }}" class="aes">
          <div class="contentCardIcon">
            <i class="me-3 iconCard fa-solid fa-wrench"></i>
          </div>
          <div class="card-body d-flex justify-content-center tituloCard">
            <h5 class="card-title mt-3">
              {{ shorcut.description }}
            </h5>
          </div>
        </a>
      </div>
    </div> -->
    <div class="text-primary mb-1 srCard" *ngFor="let shorcut of shorcuts">
      <a routerLink="{{ shorcut.route }}" class="aes d-flex gap-2">
        <div class="card cardEsp justify-content-start">
          <div class="card-body d-flex">
            <i class="me-3 srI {{shorcut.icon}}"></i>
            <h5 class="card-title mt-1">
              {{ shorcut.description }}
            </h5>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
