<nav
  class="sb-topnav navbar navbar-expand sb-sidenav-light d-flex justify-content-between shadow headerNav"
>
  <div class="d-flex px-3">
    <a
      id="sidebarToggle"
      class="d-flex align-items-center justify-content-center aes"
      href=""
    >
      <i class="fa-solid fa-bars menuIcon"></i>
      <!-- <img [src]="ImagePath.menu_icon" alt="Logo" class="menuIcon" /> -->
      <!-- <h3 class="mt-2 SubTitulo">Menu</h3> -->
    </a>
  </div>

  <!-- <div class="caja_especial"></div> -->

  <a class="navbar-brand px-3" routerLink="/home">
    <img [src]="ImagePath.logo_new" alt="Logo" class="logoNav" />
  </a>
</nav>
