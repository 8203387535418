<div id="layoutSidenav_nav">
  <nav
    class="sb-sidenav accordion sb-sidenav-dark pt-4 menuBar"
    id="sidenavAccordion"
  >
    <div class="sb-sidenav-menu overflow-auto">
      <div class="nav h-100">
        <div class="d-flex flex-row">
          <div class="d-flex col-md-4 justify-content-center pt-3" *ngIf="imgUser != null">
            <img src="data:image/png;base64,{{ imgUser }}"  class="iconUser"/>  
          </div>
          <div class="d-flex col-md-4 justify-content-center pt-2 pb-3" *ngIf="imgUser == '' || imgUser == null">
            <img [src]="ImagePath.noimg" class="iconUser2 flex-1 px-1" />
          </div>
        
          <div class="justify-content-center d-flex flex-column profile col-md-8">
            <h5>{{ content.name }}</h5>
            <h6>{{ Rol }}</h6>
          </div>
        </div>
        <hr />

        <!-- 

    Para la carga del menu se anidaron 3 bucles, los mismos recorren el array
    para obtener los valores del menu, asimismo cada valor generado se valida con el CodeModule
    y el submenu con el CodeDivision.


    Validacion para los modulos
    *ngIf="Menu_a.codeDivision == 0 && Menu_a.codeOption == 0 ">

    Validacion para las Division
    *ngIf="Menu_a_1.codeModule == Menu_a.codeModule && Menu_a_1.codeDivision != 0 && Menu_a_1.codeOption == 0

    Validacion para las opciones
    *ngIf="Menu_a_2.codeModule == Menu_a.codeModule && Menu_a_2.codeDivision == Menu_a_1.codeDivision && 
     Menu_a_2.codeOption != 0 &&  Menu_a_2.codeFeature == 0

-->

        <!-- Bucle nivel 1 -->
        <!-- Primer Bucle para los modulos (Opciones) -->
        <div
          class="d-flex flex-column h-100 flex-4 overflow-auto scrollBlack"
          id=""
        >
          <div *ngFor="let Menu_a of Menu_link" class="">
            <a
              class="nav-link optionMenu"
              routerLink="/home"
              *ngIf="Menu_a.codeModule == 0"
            >
              <div class="sb-nav-link-icon">
                <i class="{{Menu_a.icon}}"></i>
              </div>
              {{ Menu_a.description }}
            </a>
            <div
              *ngIf="
                Menu_a.codeDivision == 0 &&
                Menu_a.codeOption == 0 &&
                Menu_a.codeModule != 0
              "
              class=""
            >
              <a
                class="nav-link collapse optionMenu"
                href=""
                data-bs-toggle="collapse"
                attr.data-bs-target="{{ '#M' + Menu_a.idMenu }}"
                aria-expanded="true"
                aria-controls="collapsePages"
              >
                <div class="sb-nav-link-icon">
                  <i class="{{Menu_a.icon}}"></i>
                </div>
                {{ Menu_a.description }}
                <div class="sb-sidenav-collapse-arrow">
                  <i class="fa-solid fa-angle-down"></i>
                </div>
              </a>
              <div
                class="collapse moduleFlex"
                id="M{{ Menu_a.idMenu }}"
                aria-labelledby="headingTwo"
                data-bs-parent="#sidenavAccordion"
              >
                <nav
                  class="sb-sidenav-menu-nested nav accordion m-0"
                  id="sidenavAccordionPages"
                >
                  <!-- Bucle de nivel 2 -->
                  <div *ngFor="let Menu_a_1 of Menu_link">
                    <div
                      class=""
                      *ngIf="
                        Menu_a_1.codeModule == Menu_a.codeModule &&
                        Menu_a_1.codeDivision != 0 &&
                        Menu_a_1.codeOption == 0
                      "
                    >
                      <a
                        class="nav-link collapsed optionMenu lvl1"
                        href=""
                        data-bs-toggle="collapse"
                        attr.data-bs-target="#M{{ Menu_a_1.idMenu }}"
                        aria-expanded="true"
                        aria-controls="pagesCollapseAuth"
                      >
                        <div class="sb-nav-link-icon">
                          <i class="{{Menu_a_1.icon}}"></i>
                        </div>
                        {{ Menu_a_1.description }}
                        <div class="sb-sidenav-collapse-arrow">
                          <i class="fa-solid fa-angle-down"></i>
                        </div>
                      </a>

                      <!-- Bucle de nivel 3 -->
                      <div *ngFor="let Menu_a_2 of Menu_link">
                        <div
                          class="collapse"
                          id="M{{ Menu_a_1.idMenu }}"
                          aria-labelledby="headingOne"
                          data-bs-parent="#sidenavAccordionPages"
                        >
                          <nav class="sb-sidenav-menu-nested nav m-0">
                            <div
                              *ngIf="
                                Menu_a_2.codeModule == Menu_a.codeModule &&
                                Menu_a_2.codeDivision ==
                                  Menu_a_1.codeDivision &&
                                Menu_a_2.codeOption != 0 &&
                                Menu_a_2.codeFeature == 0
                              "
                            >
                              <!-- Si es Diferente de NA Mostrar  -->
                              <!-- Si viene Vacia Redirige al Home -->
                              <a
                                class="nav-link optionMenu lvl2"
                                routerLink="{{
                                  Menu_a_2.route === null
                                    ? '/home'
                                    : Menu_a_2.route
                                }}"
                                *ngIf="Menu_a_2.critico != 'NA'"
                              >
                                <div class="sb-nav-link-icon">
                                  <i class="{{Menu_a_2.icon}}"></i> 
                                </div>
                                {{ Menu_a_2.description }}
                              </a>

                              <!-- Si es igual a NA(No Autorizado) Mostrar Deshabilitado -->
                              <a
                                class="nav-link disabled lvl2"
                                style="color: rgb(146, 144, 144)"
                                *ngIf="Menu_a_2.critico == 'NA'"
                              >
                                <div class="sb-nav-link-icon">
                                  <i class="{{Menu_a_2.icon}}"></i>
                                </div>
                                {{ Menu_a_2.description }}
                              </a>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <hr />
          <div class="sb-sidenav-footer flex-1">
            <a class="dropdown-item" routerLink="" (click)="logout_1()">
              <div class="d-flex align-items-center justify-content-center">
                <img [src]="ImagePath.Logout" class="Logout" />
                <h5 class="Logout_1">Cerrar Sesión</h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
